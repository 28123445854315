import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom'; // Đảm bảo bạn đã import Link nếu cần
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ModalLoginInfo from '../../AccountManagerPage/components/ModalLoginInfo';

function PaginatedTable({ newDevices, pageSize }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [sortConfig, setSortConfig] = useState({ key: 'addTime', direction: 'asc' });

    const [account, setAccount] = useState(null);


    const itemsPerPage = pageSize;
    const pagesVisited = currentPage * itemsPerPage;

    useEffect(() => {
        if (account) {
            // First hide any existing modal
            $('#modalLoginInfo').modal('hide');
            // Then show with new data
            setTimeout(() => {
                $('#modalLoginInfo').modal('show');
            }, 100);
        }
    }, [account]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const sortedDevices = React.useMemo(() => {
        const sortableItems = [...newDevices];
        sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
        return sortableItems;
    }, [newDevices, sortConfig]);

    const displayDevices = sortedDevices.slice(pagesVisited, pagesVisited + itemsPerPage);

    const handlePageClick = (event) => {
        setCurrentPage(event.selected);
    };

    return (
        <div className='pagination-container' style={{marginBottom: "40px"}}>
            {account? <ModalLoginInfo facebookId={account.facebookId} lastAccess={account.lastLogin}></ModalLoginInfo>: ''}

            <table className="table table-hover">
                <thead>
                    <tr className="text-center">
                        <th className="align-middle" scope="col">No.</th>
                        <th className="align-middle" scope="col">
                            AddTime
                            <button
                                className="btn btn-outline-secondary ml-1 p-1"
                                onClick={() => requestSort("addTime")}>
                                <i className="fas fa-sort" />
                            </button>
                        </th>
                        <th className="align-middle" scope="col">DeviceId</th>
                        <th className="align-middle" scope="col">App</th>
                        <th className="align-middle" scope="col">Platform</th>
                        <th className="align-middle" scope="col">Uid</th>
                        <th className="align-middle" scope="col">Name</th>
                        <th className="align-middle" scope="col">PaidUser</th>
                        <th className="align-middle" scope="col">Reply Admin</th>
                        <th className="align-middle" scope="col">LastUse</th>
                        <th className="align-middle" scope="col" style={{ width: "150px" }}>
                            SmartLook
                            <button
                                className="btn btn-outline-secondary ml-1 p-1"
                                onClick={() => {requestSort("setSmartLook")}}>
                                <i className="fas fa-sort" />
                            </button>
                        </th>
                        <th className="align-middle" scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {displayDevices.map((item, index) => {
                        return (
                            <tr key={item.deviceId}>
                                <td className="align-middle text-center" scope="col">{index + 1 + currentPage * itemsPerPage}</td>
                                <td className="align-middle text-center" scope="col">{new Date(item.addTime).toLocaleString()}</td>
                                <td className="align-middle text-center" scope="col">{item.deviceId}</td>
                                <td className="align-middle text-center" scope="col">{item.language}</td>
                                <td className="align-middle text-center" scope="col">{item.platform}</td>
                                <td className="align-middle text-center" scope="col">{item.uid}</td>
                                <td className="align-middle text-center" scope="col">{item.name}</td>
                                {!item.paidUser ?
                                    <td className="align-middle text-center text-danger" scope="col"><strong>FALSE</strong></td> :
                                    <td className="align-middle text-center text-success" scope="col"><strong>TRUE</strong></td>}
                                {!item.replyAdmin ?
                                    <td className="align-middle text-center text-danger" scope="col"><strong>FALSE</strong></td> :
                                    <td className="align-middle text-center text-success" scope="col"><strong>TRUE</strong></td>}
                                {item.uid && item.uid !== '' ?
                                    <td scope="col" className="align-middle text-center">
                                        {new Date(item.lastLogin).toLocaleString()}
                                        <button
                                            onClick={() => { 
                                                setAccount(null);
                                                setTimeout(() => {
                                                    setAccount({
                                                        facebookId: item.facebookId,
                                                        lastLogin: item.lastLogin,
                                                    });
                                                }, 100);
                                            }}
                                            className="btn-red float-right"><i className="fa fa-eye"></i></button>
                                    </td>   
                                    :
                                    <td scope="col" className="align-middle text-center">Chưa đăng nhập</td>}
                                {(item.setSmartLook) ? (
                                    <>
                                        {item.linkSessionSmartLook && item.linkSessionSmartLook !== "" ?
                                            <td className="align-middle text-center text-success" scope="col">
                                                <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                                                    <strong>ENABLE</strong>
                                                </a>
                                            </td>
                                            :
                                            <td className="align-middle text-center text-danger" scope="col">
                                                <strong>ENABLE {item.errorSessionSmartLook ? '('+ item.errorSessionSmartLook + ')' : ''}</strong>
                                            </td>
                                        }
                                    </>
                                ) : (
                                    item.linkSessionSmartLook ?
                                        <td className="align-middle text-center text-primary" scope="col">
                                            <a href={item.linkSessionSmartLook} target="_blank" rel="noopener noreferrer">
                                                <strong>DISABLE</strong>
                                            </a>
                                        </td> :
                                        <td className="align-middle text-center text-danger" scope="col"><strong>DISABLE</strong></td>
                                )}
                                <td className="align-middle text-center" scope="col">
                                    {item.uid && item.uid !== '' ?
                                        <Link to={`/admin/support-new/${item.uid}`}>
                                            <button type="button" className="btn btn-success">Nhắn Tin</button>
                                        </Link> : <Link to={`/admin/support-new/${item.deviceId}`}>
                                            <button type="button" className="btn btn-success">Nhắn Tin</button>
                                        </Link>}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>

            {/* Pagination */}
            <ReactPaginate
            className=''
                previousLabel={"Previous"}
                nextLabel={"Next"}
                pageCount={Math.ceil(newDevices.length / itemsPerPage)}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center float-none"}
                activeClassName={"active"}
            />
        </div>
    );
}

export default PaginatedTable;
