import React, { useEffect, useState } from "react";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CustomizedSwitches from "./component/CustomizedSwitches";
import PropTypes from 'prop-types';
import { lighten, makeStyles } from '@material-ui/core/styles';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import TextField from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import { vietcomBankService } from './services/vietcomBankService';

const DEFAULT_PASSWORD = "*******";
const NEW_CONFIRM_TITLE = "TẠO TÀI KHOẢN";
const OLD_CONFIRM_TITLE = "CẬP NHẬT";


function createData( id, accountNumber, isEnable, lastUpdate, password, username, propType, accountLevel ) {
  if (propType == "VCB_PROP") { 
    propType = "VietcomBank" 
  } else { propType = "Chưa cập nhật" }
  if (!lastUpdate) lastUpdate = new Date().getTime();
  const date = new Date(lastUpdate);
  const formatter = new Intl.DateTimeFormat('en-GB', {
    timeZone: 'Asia/Ho_Chi_Minh',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  lastUpdate = formatter.format(date);
  password = DEFAULT_PASSWORD;
  if (!accountLevel) accountLevel = 0;
  return { id, accountNumber, isEnable, lastUpdate, password, username, propType, accountLevel };
}

// const data = [
//   createData('2', 123123123, false, 1710228704608, "123123123", "0858590159", "VCB_PROP", 10),
//   createData('4', 456456, true, 1710228704608, "123123", "0858590159", "VCB_PRO", 12)
// ];


const useStyles = makeStyles({
  root: { margin: 100 },
  table: { minWidth: 500 },
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    border: '1px solid #dadde9',
    '& p': {
      margin: '4px 0',
    },
    '& p:first-child': {
      fontWeight: 'bold',
      marginBottom: 8,
    }
  }
});

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  if (!Array.isArray(array)) {
    console.error("stableSort expects an array but got:", array);
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

const headCells = [
  { id: 'isSelected', disablePadding: false, label: '' },
  { id: 'accountNumber', disablePadding: true, label: 'Số tài khoản' },
  { id: 'accountLevel', disablePadding: true, label: 'Level tài khoản' },
  { id: 'username', disablePadding: false, label: 'Tên tài khoản' },
  { id: 'password', disablePadding: false, label: 'Mật khẩu' },
  { id: 'propType', disablePadding: false, label: 'Ngân hàng' },
  { id: 'isEnable', disablePadding: false, label: 'Trạng thái' },
  { id: 'lastUpdate', disablePadding: false, label: 'Ngày cập nhật' }
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ backgroundColor: '#76b5c5' }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'right'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected, handleClickOpenDialog, onRemoveAccounts } = props;

  return (
    <Toolbar className={classes.root}>
      <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
        Danh sách Account Ngân hàng
      </Typography>
      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton 
            aria-label="delete"
            onClick={onRemoveAccounts}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Add account">
          <IconButton
            aria-label="filter list"
            onClick={(e) => handleClickOpenDialog(-1)}
          >
            <AddCircleIcon fontSize="large"/>
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};


function ManageVietcomBank() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [buttonName, setButtonName] = useState("TẠO TÀI KHOẢN");
  const [loading, setLoading] = useState({
    fetch: false,
    save: false,
    delete: false
  });

  const [newAccount, setNewAccount] = useState({
    id: null,
    accountNumber: 0,
    accountLevel: 0,
    username: '',
    password: '',
    propType: 'VietcomBank',
    isEnable: false,
    lastUpdate: new Date().getTime(),
  });

  function resetNewAccount() {
    setNewAccount({
      accountNumber: 0,
      accountLevel: 0,
      username: '',
      password: '',
      propType: 'VietcomBank',
      isEnable: false,
    })
  }

  
  const handleClickOpenDialog = (index) => {
    if (index !== -1) {
      const oldRow = rows[index];
      console.log("Step1 : ", oldRow.id)
      setNewAccount({
        id: oldRow.id,
        accountNumber: oldRow.accountNumber,
        accountLevel: oldRow.accountLevel,
        username: oldRow.username,
        password: oldRow.password,
        propType: oldRow.propType,
        isEnable: oldRow.isEnable,
        lastUpdate: oldRow.lastUpdate
      })
      setButtonName(OLD_CONFIRM_TITLE);
    } else {
      setButtonName(NEW_CONFIRM_TITLE);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    resetNewAccount();
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

   const handleDoubleClick = () => {
      console.log("double click")
   }

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const handleChange = (event, rowIndex) => {
    setRows(prevRows => {
      const newRows = [...prevRows];
      newRows[rowIndex].isEnable = event.target.checked;
      return newRows;
    });
  };

  const handleInputChange = (e) => {
    let { name, value } = e.target;
    
    if (name === "accountNumber") {
      value = value === '' ? 0 : Number(value);
      if (isNaN(value)) value = 0;
    } else if (name === "accountLevel") {
      value = value === '' ? 0 : Number(value);
    } else if (name === "isEnable") {
      value = e.target.checked;
    }
    
    setNewAccount({ ...newAccount, [name]: value });
  };

  const handleAddAccount = () => {
    if (newAccount.password === DEFAULT_PASSWORD) newAccount.password = '';

    if (newAccount.lastUpdate) newAccount.lastUpdate = null;

    if (buttonName === NEW_CONFIRM_TITLE &&
      (!newAccount.accountNumber || !newAccount.username || !newAccount.password)) {
        return toast.warning("Bạn cần điền đầy đủ thông tin khi tạo");
    }

    if (buttonName === OLD_CONFIRM_TITLE && 
      (!newAccount.accountNumber || !newAccount.username)) {
      return toast.warning("Bạn cần điền đầy đủ thông tin");
    }

    const accountToSave = {
      ...newAccount,
      accountNumber: Number(newAccount.accountNumber),
      accountLevel: Number(newAccount.accountLevel),
      propType: "VCB_PROP"  // Always set to VCB_PROP since we only handle VietcomBank
    };
    
    setOpenDialog(false);
    createAccount(accountToSave);
  };

  const handleRemoveAccounts = async () => {
    if (!selected.length) return;
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setLoading(prev => ({ ...prev, delete: true }));
    console.log(JSON.stringify(selected))
    try {
      const response = await vietcomBankService.deleteAccounts(selected);
      if (response.status === "OK") {
        // setRows(prevRows => prevRows.filter(acc => !selected.includes(acc.id)));
        // setSelected([]);
        await getAccount();
        toast.success(response.message || "Xoá tài khoản thành công");
      } else {
        toast.warning(response.message || "Không thể xoá tài khoản");
      }
    } catch (error) {
      toast.error(error.message || "Đã xảy ra lỗi khi xoá tài khoản");
    } finally {
      setLoading(prev => ({ ...prev, delete: false }));
      setOpenConfirmDialog(false);
    }
  };

  useEffect(() => {
    // setRows(data);
    getAccount();
  }, [])

  const getAccount = async () => {
    setLoading(prev => ({ ...prev, fetch: true }));
    try {
      const response = await vietcomBankService.getAccounts();
      let accounts = response?.data?.account ?? [];
      if (response.status === "OK" && Array.isArray(accounts)) {
        accounts = accounts.map((e) => 
          createData(e.id, e.accountNumber, e.isEnable, e.lastUpdate, e.password, e.username, e.propType)
        );
        setRows(accounts);
        // toast.success(response.message);
      } else {
        toast.warning(response.message);
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while fetching accounts");
    } finally {
      setLoading(prev => ({ ...prev, fetch: false }));
    }
  };

  const createAccount = async (account) => {
    setLoading(prev => ({ ...prev, save: true }));
    try {
      const response = await vietcomBankService.createOrUpdateAccount(account);
      if (response.status === "OK") {
        await getAccount();
        // setRows((prevRows) => {
        //   const index = prevRows.findIndex((acc) => acc.id === account.id);
        //   if (index !== -1) {
        //     return prevRows.map((acc) => (acc.id === account.id 
        //       ? createData(account.id, account.accountNumber, account.isEnable, account.lastUpdate, account.password, account.username, account.propType) 
        //       : acc));
        //   } else {
        //     return [...prevRows, createData(account.id, account.accountNumber, account.isEnable, account.lastUpdate, account.password, account.username, account.propType)];
        //   }
        // });
        // toast.success(response.message);
      } else {
        toast.warning(response.message || "Failed to create/update account");
      }
    } catch (error) {
      toast.error(error.message || "An error occurred while saving the account");
    } finally {
      setLoading(prev => ({ ...prev, save: false }));
    }
    resetNewAccount();
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar 
          numSelected={selected.length} 
          handleClickOpenDialog={handleClickOpenDialog}
          onRemoveAccounts={handleRemoveAccounts}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
            /> 
            <TableBody>
              {
              stableSort(rows, getComparator(order, orderBy))
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      onDoubleClick={(e) => handleClickOpenDialog(index)}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => handleClick(event, row.id)}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" align="right">{row.accountNumber}</TableCell>
                      <TableCell component="th" scope="row" align="right">{row.accountLevel}</TableCell>
                      <TableCell align="right">{row.username}</TableCell>
                      <TableCell align="right">{row.password}</TableCell>
                      <TableCell align="right">{row.propType}</TableCell>
                      <TableCell align="right">
                        <CustomizedSwitches
                          state={row.isEnable} 
                          //disable click outside
                          // handleChange={(e) => handleChange(e, index)}
                        />
                      </TableCell>
                      <TableCell align="right">{row.lastUpdate}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Thêm tài khoản</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Điền thông tin cần thiết để thêm mới tài khoản
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Số tài khoản"
            name="accountNumber"
            type="number"
            placeholder="Nhập số tài khoản bằng số"
            fullWidth
            defaultValue={newAccount.accountNumber}
            onChange={handleInputChange}
          />
          <Tooltip 
            title={
              <div>
                <p>Hướng dẫn set level tài khoản theo cấu trúc:</p>
                <p>- Level 0: User level từ 0 - 9</p>
                <p>- Level 1: User level từ 10 - 19</p>
                <p>- Level 2: User level từ 20 - 29</p>
              </div>
            }
            placement="right"
            arrow
            classes={{ tooltip: classes.tooltip }}
          >
            <TextField
              autoFocus
              margin="dense"
              label="Level tài khoản"
              name="accountLevel"
              type="number"
              placeholder="Nhập level tối thiếu để thấy được tài khoản"
              fullWidth
              defaultValue={newAccount.accountLevel}
              onChange={handleInputChange}
            />
          </Tooltip>
          <TextField
            margin="dense"
            label="Tên tài khoản"
            name="username"
            fullWidth
            value={newAccount.username}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Mật khẩu"
            name="password"
            fullWidth
            value={newAccount.password}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            label="Ngân hàng"
            name="propType"
            fullWidth
            value={newAccount.propType}
            onChange={handleInputChange}
            InputProps={{
              readOnly: true,
            }}
          />
          <CustomizedSwitches state={newAccount.isEnable} handleChange={handleInputChange}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" disabled={loading.save || loading.delete}>
            Huỷ
          </Button>
          <Button 
            onClick={handleAddAccount} 
            color="primary"
            disabled={loading.save}
          >
            {loading.save ? 'Đang lưu...' : buttonName}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Confirmation Dialog for deletion */}
      <Dialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Xác nhận xoá tài khoản"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {selected.length > 1 
              ? `Bạn có chắc chắn muốn xoá ${selected.length} tài khoản đã chọn không?`
              : "Bạn có chắc chắn muốn xoá tài khoản này không?"
            }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenConfirmDialog(false)} 
            color="primary"
            disabled={loading.delete}
          >
            Huỷ
          </Button>
          <Button 
            onClick={handleConfirmDelete} 
            color="secondary" 
            autoFocus
            disabled={loading.delete}
          >
            {loading.delete ? "Đang xoá..." : "Xoá"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default ManageVietcomBank;
