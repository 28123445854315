import React, {Component} from 'react';
import axios from 'axios';
import {toast} from 'react-toastify';

import bindModel from '../../../common/bindModel.js';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';
import $ from 'jquery';
import AWS from 'aws-sdk';

export default class ModalUpdateBanner extends Component {
    constructor(props) {
        super(props);

        this.state = {
            banner: null,
            loading: false
        }

        this.uploadThumbnailForWasabi = this.uploadThumbnailForWasabi.bind(this);
        this.saveBanner = this.saveBanner.bind(this);
        this.updateStartDate = this.updateStartDate.bind(this);
        this.updateEndDate = this.updateEndDate.bind(this);
    }

    convertDateTime(value) {
        let arr = value.split(",");
        let arrDate = arr[1].trim().split("/");

        arrDate[1] = arrDate[1].length == 1 ? "0" + arrDate[1] : arrDate[1];
        arrDate[0] = arrDate[0].length == 1 ? "0" + arrDate[0] : arrDate[0];
        let date = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
        return date + "T" + arr[0].substring(0,5);

    }

    componentWillReceiveProps(props) {
        let banner = JSON.parse(JSON.stringify(props.banner));
        // if(banner != null) {
        //     banner.startDate = this.convertDateTime(new Date(banner.startDate).toLocaleString());
        //     banner.endDate = this.convertDateTime(new Date(banner.endDate).toLocaleString());
        // }
        
        this.setState({
            banner : banner
        })
    }

    guid() {
        function s4() {
          return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }


    uploadThumbnailForWasabi(event) {

      
    
     //05Dec24 Check if the file size exceeds 1MB
     if ( event.target.files[0].size > 1048576) { // 1MB in bytes
        toast.error("Hình ảnh không được vượt quá dung lượng 1Mb.");
        
        }

        this.setState({
            loading: true
        });

        // var credentials = new AWS.Credentials("0IOD12IF62UYZ22355HV", "H1hKICheXJrZCwJlIu6BeBV21laYErelMgArrMsu");
        //     var ep = new AWS.Endpoint("s3.wasabisys.com");
        //     var s3 = new AWS.S3({
        //     endpoint: ep,
        //     credentials: credentials,
        //     region: 'us-east-1'
	    // });


        var banner = this.state.banner;
        var target = event.target;
	    var bucketName = "ikara-data/images/banners";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
		var keyName = this.guid() + "." + extension;
		var contentType = "image/jpeg";
		if (extension == "png") contentType = "image/png";

		var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    banner.thumbnail = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        banner : banner
                    });

                }.bind(this),
            });
        }.bind(this);

	    // fileReader.onload = function () {
	    //   var data = fileReader.result; // data <-- in this var you have the file data in Base64 format
	    //   var params = {
		// 			ACL: 'public-read',
		// 			ContentType : contentType,
		// 			Bucket: bucketName, 
		// 			Key: keyName, 
		// 			Body: data
		// 	};
		// 	s3.putObject(params, function(err, data) {
		// 		if (err){
		// 			alert("Upload Failed " + err);
		// 		} else{
        //             console.log("OK");
        //             banner.thumbnail = 'https://s3.wasabisys.com/' + bucketName + '/' + keyName;
        //             this.setState({
        //                 banner : banner
        //             })
		// 		}
		// 	}.bind(this));
	    // }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);
		
    }

    saveBanner() {
        this.setState({
            loading: true
        });

        // this.state.banner.startDate += ":00";
        // this.state.banner.endDate += ":00";
        axios.post(global.config.apiDomain + '/rest/banners/save', JSON.stringify(this.state.banner), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });

            window.$("#modalUpdateBanner").modal("hide");

            toast.success('Cập nhật thành công'); 
            this.props.resetList(res.data);
        })
        .catch(error => {
            this.setState({
                loading: false
            });
            console.error("Error occurred:", error);
            toast.error("Lỗi server, xử lý lại nha");
        });
    }

    updateStartDate(props) {
        let {banner} = this.state;
        let currentTime = props.value
        if(banner) {
            banner.startDate = currentTime;
            this.setState({
                banner: banner
            });
        }
        
    }

    updateEndDate(props) {
        let {banner} = this.state;
        let currentTime = props.value
        if(banner) {
            banner.endDate = currentTime;
            this.setState({
                banner: banner
            });
        }
        
    }

    render() {
        const model = bindModel(this);
        const {banner} = this.state;
        return (
            <>
                <div className="modal fade" id="modalUpdateBanner" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">Cập nhật Banner</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            {banner ? (<div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control mb-2" 
                                            type="text" 
                                            name="startDate"
                                            readOnly={true}
                                            {...model('banner.id')}/>
                                    </div>
                                </div>
                                <div id="thumbnail" className="row mb-2">
                                    <div className="col-3">
                                        <label>Thumbnail</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            type="file" 
                                            className="custom-file-input" 
                                            id="inputGroupFile03"
                                            onChange={this.uploadThumbnailForWasabi} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {banner.thumbnail ? (
                                    <div id="thumbnail" className="row mb-2">
                                        <div className="col-3">
                                        
                                        </div>
                                        <div className="col-9">
                                            <img src={banner.thumbnail} />

                                        </div>
                                    </div>
                                ) : ""}
                            
                                <div id="startDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày bắt đầu</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input 
                                            className="form-control mb-2" 
                                            type="datetime-local" 
                                            name="startDate"
                                            {...model('banner.startDate')}/> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateStartDate}
                                            value = {new Date(banner.startDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ngày kết thúc</label>
                                    </div>
                                    <div className="col-9">
                                        {/* <input 
                                            className="form-control" 
                                            type="datetime-local" 
                                            name="endDate" 
                                            {...model('banner.endDate')}/> */}
                                        <DateTimePickerComponent 
                                            change= {this.updateEndDate}
                                            value = {new Date(banner.endDate)}
                                        >    
                                        </DateTimePickerComponent>
                                    </div>
                                </div>
                                <div id="endDate" className="row mb-2">
                                    <div className="col-3">
                                        <label>Màu nền</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="color" 
                                            name="endDate" 
                                            // value={eventEntity.startDate} 
                                            {...model('banner.backgroundColor')}/>
                                    </div>
                                </div>
                                <div id="link" className="row mb-2">
                                    <div className="col-3">
                                        <label>Link</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="text" 
                                            name="url" 
                                            {...model('banner.url')}/>
                                    </div>
                                </div>

                                <div id="linkType" className="row mb-2">
                                    <div className="col-3">
                                        <label>Loại Link</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            name="linkType"
                                            {...model('banner.linkType')} 
                                            >
                                            <option value="">Chọn</option>
                                            <option value="MyApp">MyApp</option>
                                            <option value="WebView">WebView</option>
                                            <option value="AnotherApp">AnotherApp</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div id="order" className="row mb-2">
                                    <div className="col-3">
                                        <label>Order</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="number" 
                                            name="order" 
                                            {...model('banner.order')}/>
                                    </div>
                                </div>

                                <div id="requiredLevel" className="row mb-2">
                                    <div className="col-3">
                                        <label>Level Yêu cầu</label>
                                    </div>
                                    <div className="col-9">
                                        <input 
                                            className="form-control" 
                                            type="number" 
                                            name="requiredLevel" 
                                            {...model('banner.requiredLevel')}/>
                                    </div>
                                </div>

                                <div>
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Package</th>
                                                <th scope="col">Platform</th>
                                                <th scope="col">Min version</th>
                                                <th scope="col">Max version</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {banner.minMaxVersions.map((version, index) => {
                                            return (
                                                <tr key={version.id}>
                                                    <td>{version.packageName}</td>
                                                    <td>{version.platform}</td>
                                                    <td><input className="form-control" value={version.minVersion} {...model('banner.minMaxVersions['+index+'].minVersion')}/></td>
                                                    <td><input className="form-control" value={version.maxVersion} {...model('banner.minMaxVersions['+index+'].maxVersion')}/></td>
                                                </tr>)
                                            })}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>) : ''}
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ? 
                                (
                                <button style={{width: "120px"}} className="btn btn-red" type="button" disabled>
                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                                ) :
                                (
                                <button  style={{width: "120px"}} type="button" className="btn btn-red" onClick={this.saveBanner}>Xác nhận</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}