import React, {Component} from 'react';
import axios from 'axios';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { withRouter } from 'react-router-dom';
import MyContext from '../../common/MyContext'

import { setCookie } from '../../common/cookie.js';
import { detectApp, detectMob } from '../../common/utils.js';
import { toast } from 'react-toastify';
class LinkedAccountPage extends Component {

    constructor(props) {
        super(props);
    }

    

    handleResponse = (data) => {    
        let self = this;
        if (data.accessToken != null) {
            console.log(data)
            const json = JSON.stringify({ domain: window.location.hostname, accessToken: data.accessToken });
    
            axios.post(global.config.apiDomain + '/rest/linkedaccount', json, {
                headers: {
                'Content-Type': 'application/json'
                }
          }).then(res => {
            console.log("login")
            const { response } = res.data;
            if(response.status === "OK") {
                toast.success(response.message);
                toast.success("Vui lòng đăng xuất rồi đăng nhập lại");
            } else {
                toast.warn(response.message);
            }
            
          })
        }
      }

    facebookLoginButton(renderProps) {
        return (
        <div className="p-1 text-center">
            <button
            onClick={renderProps.onClick}
            className="btn w-25 facebook-login text-center">
            <span className="float-left login-icon">
                <img className="" src={require('../../assets/images/facebook_icon.svg').default} />
            </span>
            <label className="text-white my-1">Liên kết tài khoản</label>
            <span className="login-icon float-right"></span>
            </button>
        </div>
        )
    }

    render() {

        const { t } = this.props;

        return (
            <div id="content">
                <FacebookLogin
                  appId="607045324967390"
                  autoLoad={false}
                  fields="name,email,picture"
                  isMobile={false}
                  xfbml={true}
                  version="3.1"
                  redirectUri={window.location.href}
                  disableMobileRedirect={true}
                  callback={this.handleResponse}
                  render={(renderProps) => (
                    this.facebookLoginButton(renderProps)
                  )}
                />
            </div>
        );
    }
}

LinkedAccountPage.contextType = MyContext;
export default withRouter(LinkedAccountPage);