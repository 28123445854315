import React, { Component } from 'react';
import MyContext from '../common/MyContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import bindModel from '../common/bindModel.js';

import '../assets/css/subscribe.css'
import { detectApp } from '../common/utils';
import VipOkara from "../assets/images/vip_okara.svg";
const $ = window.$;


class SubscribeOkaraDialog extends Component {
    constructor(props) {
        super(props);

        this.sendOTP = this.sendOTP.bind(this);
        this.state = {
            registerVipType: "DAY",
            chargeType: ""
        }
    }

    sendOTP() {
        $("#modalGetVipOkara").modal("hide");
        if(chargeType === "" || chargeType === null) {
            $("#modalVerifyOTPOkara").modal("show");
        } 
        const { user } = this.context;
        const {registerVipType, chargeType} = this.state;
        const json = {
            facebookId: user.facebookId,
            domain: window.location.hostname,
            type: registerVipType,
            chargeType: chargeType
        }

        axios.post(global.config.apiDomain + '/rest/okara/subscribe', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {
                if(chargeType === "m" || chargeType === "mr") {
                    toast.success("Por favor, digita o teu PIN e-Mola no teu telefone e aguarda a mensagem de confirmacao. Obrigado!");
                    this.loadUser();
                } 
                this.context.updateRegisterVipType(registerVipType);
            } else if(res.data.status == "WAITING") {
                toast.warning(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        });
    }

    loadUser() {
        const json = JSON.stringify({ domain: window.location.hostname});

		axios.post(global.config.apiDomain + '/rest/loginuser', json, {
			headers: {
			  'Content-Type': 'application/json'
			}
		  }).then(res => {
			const {user} = res.data;
			this.context.updateUser(user); 
		  })
    }

    render() {
        const { user } = this.context;
        const {t} = this.props;
        const {registerVipType} = this.state;
        const model = bindModel(this);
        return (
            <div className="modal fade" id="modalGetVipOkara" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div style={{ "borderRadius": "20px" }} className="modal-content">
                        <div>
                            <button type="button" className="btn float-right" data-dismiss="modal" aria-label="Close">
                                <i className='fa fa-close' />
                            </button>
                        </div>
                        <div style={{ "paddingBottom": "2.0rem", "paddingRight": "2.0rem", "paddingLeft": "2.0rem" }} className="modal-body pt-0">
                            <div id="subscribe">
                                <div className={'d-flex align-items-center'}>
                                    <div className="col-3 text-center">
                                        <img
                                            style={{"objectFit": "cover"}}
                                            className="rounded-circle"
                                            src={user.profileImageLink} />
                                        <img
                                            style={{ "position": "absolute", "bottom": "0", "left": "35px", "width": "40px" }}
                                            src={require('../assets/images/vip_okara.svg').default}
                                        />
                                    </div>
                                    <div className="col-9">
                                        <h3 className='font-weight-bold p-0 m-0'>{t("subscribe_okara_dialog.upgrade_vip_now","Nâng cấp VIP ngay")}</h3>
                                        <p className='p-0 m-0'>{t("subscribe_okara_dialog.upgrade_vip_now_description","Để nhận những lợi ích độc quyền")}</p>
                                    </div>
                                </div>
                                {detectApp("mz.okara") && (
                                    <div className="subscription-card mt-4">
                                    <select {...model('chargeType')} className="form-control select-charge">
                                        <option value="">Paga via conta basica</option>
                                        <option value="m">Paga via e-Mola</option>
                                        <option value="mr">Pagamento via e-Mola renavacao automatica</option>
                                    </select>
                                </div>
                                )}
                                <div
                                    onClick={() => {
                                        this.setState({
                                            registerVipType: "DAY"
                                        })
                                    }}
                                    className={registerVipType == "DAY" ? 'text-center mt-4 p-2 selected' : 'text-center mt-4 p-2 select'} >
                                    <h4 className='font-weight-bold p-0 m-0'>{t("subscribe_okara_dialog.trial_a_day","Dùng thử 1 ngày FREE")}</h4>
                                    <p className='p-0 m-0'>{t("subscribe_okara_dialog.trial_a_day_description","Sau đó 200 KÍP/ngày")}</p>
                                </div>
                                
                                {detectApp("mz.okara") && (
                                    <div
                                onClick={() => {
                                    this.setState({
                                        registerVipType: "WEEK"
                                    })
                                }}
                                className={registerVipType == "WEEK" ? 'text-center mt-4 p-2 selected' : 'text-center mt-4 p-2 select'} >
                                    <h4 className='font-weight-bold p-0 m-0'>{t("subscribe_okara_dialog.get_vip_a_week","7 ngày")}</h4>
                                    <p className='p-0 m-0'>{t("subscribe_okara_dialog.get_vip_a_week_description","500 KÍP/ngày")}</p>
                                </div>
                                )}
                                
                                {detectApp("mz.okara") && (
                                    <div
                                    onClick={() => {
                                    this.setState({
                                        registerVipType: "MONTH"
                                    })
                                }}
                                    className={registerVipType == "MONTH" ? 'text-center mt-4 p-2 selected' : 'text-center mt-4 p-2 select'} >
                                    <h4 className='font-weight-bold p-0 m-0'>{t("subscribe_okara_dialog.get_vip_a_month","30 ngày")}</h4>
                                    <p className='p-0 m-0'>{t("subscribe_okara_dialog.get_vip_a_month_description","1000 KÍP/ngày")}</p>
                                </div>
                                )}
                                
                                <div className='mt-4'>
                                    <p>
                                        <i className="fa fa-check text-success" />&nbsp;&nbsp;
                                        {t("subscribe_okara_dialog.rights_1","Nâng cấp VIP ngay")}
                                    </p>
                                    <p>
                                        <i className="fa fa-check text-success" />&nbsp;&nbsp;
                                        {t("subscribe_okara_dialog.rights_2","Nâng cấp VIP ngay")}
                                    </p>
                                    <p>
                                        <i className="fa fa-check text-success" />&nbsp;&nbsp;
                                        {t("subscribe_okara_dialog.rights_3","Nâng cấp VIP ngay")}
                                    </p>
                                    <p>
                                        <i className="fa fa-check text-success" />&nbsp;&nbsp;
                                        {t("subscribe_okara_dialog.rights_4","Nâng cấp VIP ngay")}
                                    </p>
                                </div>
                                <div className='mt-5 text-center'>
                                    <button
                                        id='confirm-button'
                                        onClick={this.sendOTP}
                                        className='btn btn-red w-75 text-white'>{t("subscribe_okara_dialog.register","Đăng ký")}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

SubscribeOkaraDialog.contextType = MyContext;
export default withTranslation()(SubscribeOkaraDialog)