import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import bindModel from '../../../common/bindModel';

export default class ModalCreate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false,
        }

        this.createOrUpdate = this.createOrUpdate.bind(this);
        this.uploadThumbnail = this.uploadThumbnail.bind(this);
        this.guid = this.guid.bind(this);
    }


    createOrUpdate() {
        const { model } = this.state;
        if (
            !model ||
            !model.postType ||
            !model.message ||
            !model.fanPage
        ) {
            toast.warning("Nhập đầy đủ thông tin");
            return
        }

        let allChoices = $(".inputChoice");
        let arr = [];
        allChoices && allChoices.map((index, element) => {
            if (element.value) arr.push(element.value)
        })
        model.subVideos = arr
        if (model.postType == 'VIDEO' && (!model.subVideos || model.subVideos.lenght > 1)) {
            toast.warning("Post Video chỉ 1 link video");
            return
        }
        if (model.postType == 'PLAYLIST' && (!model.subVideos || model.subVideos.lenght <= 1)) {
            toast.warning("Post Playlist phải từ 2 link video");
            return
        }

        this.setState({
            loading: true
        });

        axios.post(global.config.apiDomain + "/rest/userPost/createOrUpdate", JSON.stringify(model), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                this.setState({
                    loading: false
                });
                if (res.data && res.data.status == "OK") {
                    window.$("#modalCreate").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetList(model.facebookId);
                } else {
                    toast.success(res.data.message)
                }
            })

    }

    componentWillReceiveProps(props) {
        const { model } = props;
        if (model == null) return
        this.setState({
            model: model
        })
        console.log('componentWillReceiveProps');

        $('#subVideos').html('');
        setTimeout(() => {
            model && model.subVideos && model.subVideos.map((element, index) => {
                console.log(element);

                $("#subVideos").append(`
                <div class="row">
                    <div class='col-10'>
                        <input
                            class="form-control mb-2 inputChoice"
                            type="text"
                            placeholder="Lựa chọn"
                            value="${element}"
                        />
                    </div>
                    <div class='col-2'>
                        <button class='btn btn-danger btnDelete' 
                            onclick="this.parentElement.parentElement.innerHTML = ''"}
                        >Xóa</button>
                    </div>
                </div>
            `)
            })
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadThumbnail(event) {
        var target = event.target;


    //05Dec24 Check if the file size exceeds 1MB
    if (  event.target.files[0].size > 1048576) { // 1MB in bytes
        toast.error("Hình ảnh không được vượt quá dung lượng 1Mb.");
        return;
    }

        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }
        const { model } = this.state;

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/userPost";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    model.thumbnailImageUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        loading: false
                    });

                    this.setState({
                        model: model
                    });
                }.bind(this)
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    addVideo() {
        $("#subVideos").append(`
            <div class="row">
                <div class='col-10'>
                    <input
                        class="form-control mb-2 inputChoice"
                        type="text"
                        name="order"
                        placeholder="Nhập Link video"
                    />
                </div>
                <div class='col-2'>
                    <button class='btn btn-danger btnDelete' 
                        onclick="this.parentElement.parentElement.innerHTML = ''"}>Xóa
                    </button>
                </div>
            </div>
        `)
    }

    render() {
        const bind = bindModel(this);
        const { model } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalCreate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{model && model.recordingId ? "Cập nhật bài đăng" : "Thêm mới bài đăng"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            readOnly={true}
                                            {...bind('model.recordingId')}
                                        />
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Thể loại</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            {...bind('model.postType')}
                                        >
                                            <option value=''>CHỌN</option>
                                            <option value='STATUS'>Status</option>
                                            <option value='IMAGE'>Ảnh</option>
                                            <option value='VIDEO'>Video</option>
                                            <option value='PLAYLIST'>Playlist</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Fan Page</label>
                                    </div>
                                    <div className="col-9">
                                        <select
                                            className="form-control"
                                            type="text"
                                            {...bind('model.fanPage')}
                                        >
                                            <option value=''>Lựa chọn FanPage ...</option>
                                            <option value='Yokara'>Yokara</option>
                                            <option value='Ikara'>Ikara</option>
                                            <option value='other'>Khác</option>
                                        </select>
                                    </div>
                                </div>
                                {model && model.postType && (model.postType != 'STATUS') ? <>
                                    <div id="thumbnailImageUrl" className="row mb-2">
                                        <div className="col-3">
                                            <label>Ảnh thumbnail</label>
                                        </div>
                                        <div className="col-9">
                                            <input
                                                className="custom-file-input"
                                                type="file"
                                                placeholder="Chọn hình mô tả"
                                                onChange={this.uploadThumbnail} />
                                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                        </div>
                                    </div>
                                    {model && model.thumbnailImageUrl ?
                                        <div id="reviewThumbnailUrl" className="row mb-2">
                                            <div className="col-3">
                                            </div>
                                            <div id="show-thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                                <img src={model.thumbnailImageUrl} width="200px" />
                                            </div>
                                        </div> : ''}
                                </> : ''}


                                <div id="name" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea rows="4"
                                            className="form-control mb-2"
                                            placeholder="Nhập mô tả"
                                            {...bind('model.message')} />
                                    </div>
                                </div>

                                {model && (model.postType == 'VIDEO' || model.postType == 'PLAYLIST') ?
                                    <>
                                        <div className="row mb-2">
                                            <div className="col-3">
                                                <label>Link Video</label>
                                            </div>
                                            <div className="col-9">
                                                <div id='subVideos'>
                                                </div>

                                                <button className='btn btn-success'
                                                    onClick={this.addVideo}
                                                >+</button>
                                            </div>
                                        </div>
                                    </>
                                    : ''}

                                <div className="row mb-2">
                                    <div className="col-3">
                                        <label>Post Link</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            placeholder="Nhập deepLink bài post"
                                            {...bind('model.postLink')} />
                                    </div>
                                </div>



                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                            onClick={this.createOrUpdate}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}