import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ModalActive = ({showModalActive, uid }) => {
    const [showModalActiveSet, setshowModalActive] = useState(showModalActive);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        setshowModalActive(showModalActive);
    }, [showModalActive]);

    useEffect(() => {
        if (showModalActive && uid) {
            handleSubmitActive();
        } 
    }, [showModalActive, uid]);

    const handleInputChangeActive = (event) => {
        const { name, value } = event.target;
        if (name === 'fromDate') setFromDate(value);
        if (name === 'toDate') setToDate(value);
    };

    const handleSubmitActive = () => {
        let now = new Date();

        // đang lấy 0h ngày 1 ở UTC +7 tức 17h ngày hôm trước ở UTC +0
        let fromDateDefault = new Date(now.getFullYear(), 0, 1, 0, 0, 0);
        let timeFrom = fromDate? new Date(fromDate): fromDateDefault;
        timeFrom = new Date(timeFrom.getFullYear(), timeFrom.getMonth(), 1, 0, 0, 0);

        let timeEnd = toDate? new Date(toDate): now;
        timeEnd = new Date(timeEnd.getFullYear(), timeEnd.getMonth() + 1, 1, 0, 0, 0);
        
        const fromTimestamp = timeFrom.getTime();
        const toTimestamp = timeEnd.getTime();
        if (fromTimestamp > toTimestamp) {
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu!");
            return;
        }

        setIsLoading(true);
        axios.post(
                global.config.apiDomain + '/rest/partner-duration/web/get-partner-active-list',
                { fromDate: fromTimestamp, toDate: toTimestamp, uid: uid},
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((res) => {
                const data = res.data.data || null;
                setIsLoading(false);

                const dates = [];
                const count = [];

                if (data && data.list) {
                    Object.entries(data.list).forEach(([key, value]) => {
                        dates.push(convertISODate(key));
                        count.push(value);
                    });

                    setChartData({
                        labels: dates,
                        datasets: [
                            {
                                label: 'Số ngày CTV online',
                                data: count,
                                fill: false,
                                borderColor: '#36A2EB',
                                tension: 0.1
                            }
                        ]
                    });

                    setTotal(data.total);
                }
            })
            .catch((error) => {
                console.error('Error fetching commissions:', error);
                setIsLoading(false);
            });
    };

    const convertISODate = (isoDate) => {
        const date = new Date(isoDate);
        
        // Lấy tháng và năm
        const month = date.getMonth() + 1; // Lấy tháng (UTC, bắt đầu từ 0)
        const year = date.getFullYear(); // Lấy năm (UTC)
        
        // Định dạng thành chuỗi dd/mm/yyyy
        const formattedDate = `${month
          .toString()
          .padStart(2, "0")}/${year}`;
        
        return formattedDate;
    }

    return (
        <div>
            <div className="modal fade" id="modalActive" tabIndex="-1" role="dialog" aria-labelledby="modalCompareLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: '95%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="resultModalLabel">Biểu đồ CTV online</h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group d-flex" style={{ gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <label>From Date</label>
                                    <input
                                        type="month"
                                        className="form-control"
                                        name="fromDate"
                                        value={fromDate}
                                        onChange={handleInputChangeActive}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <label>To Date</label>
                                    <input
                                        type="month"
                                        className="form-control"
                                        name="toDate"
                                        value={toDate}
                                        onChange={handleInputChangeActive}
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" onClick={handleSubmitActive}>
                                Hiển thị
                            </button>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : chartData ? (
                                <div style={{ width: '100%', height: '360px' }}>
                                    <div className='row'>
                                        <div className='col-5'></div>
                                        <div className='col-7'><p><strong>Số ngày CTV Online:</strong> {total}</p></div>
                                    </div>
                                    <Line data={chartData} options={{ maintainAspectRatio: false }} />
                                </div>
                            ) : (
                                <p>Không có dữ liệu</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className='btn btn-secondary ml-2 mr-2' data-dismiss="modal">Hủy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalActive;