import React, { Component } from 'react';
import axios from 'axios';
import OpenApp from "react-open-app";
import ToastDownloadApp from './components/ToastDownloadApp.js';
import { Helmet } from 'react-helmet';
import { Base64 } from 'js-base64';
import { detectApp } from '../../common/utils.js';
import ErrorImg from "../../assets/images/404.png";

export default class LiveroomPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      recording: null,
      uid: this.props.match.params.liveroomId,
      app: null,
      deeplink: null,
      nameLiveroom: null,
      thumbnail: null
    };

    this.getDeeplinkLiveroom = this.getDeeplinkLiveroom.bind(this);
    this.getLiveroomInfo = this.getLiveroomInfo.bind(this);
    this.newGetKey = this.newGetKey.bind(this);
    this.getDeeplinkLiveroom();
  }

  getDeeplinkLiveroom() {
    let json = {
      uid: this.state.uid,
      domain: window.location.hostname,
      type: "LIVEROOM",
      app: "YOKARA"
    }

    const jsonBase64 = Base64.encode(JSON.stringify(json));
    var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
    var keys = jsonBase64 + "-" + pass;

    var url = "/GetDeepLinkFromParam?parameters="+keys;
      axios.post(url, json, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        const deeplink = res.data;
        console.log(res.data);
        // window.location.href = deeplink;
        this.setState({ deeplink }); 
      }, err => {
        console.log("err get deeplink:");
        console.log(err);
      });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.deeplink && this.state.deeplink !== prevState.deeplink) {
      window.location.href = this.state.deeplink;
    }
  }

  getLiveroomInfo() {
    const json = {
      cursor: null,
      language: this.state.language,
      platform: "IOS",
      query: this.state.uid
    }
    const jsonBase64 = Base64.encode(JSON.stringify(json));
    var pass = this.newGetKey(jsonBase64, 7123358024, 3564);
    var keys = jsonBase64 + "-" + pass;

    var url = "/v35.SearchLiveRooms?parameters="+keys;
      axios.post(url, json, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        this.setState({
            nameLiveroom: res.data.rooms[0].name,
            thumbnail: res.data.rooms[0].thumbnail
        });
      }, err => {
          console.log("err:");
          console.log(err);
      });
  }
  newGetKey(data, password, password2) {
    var listOfNumbers = "" + password2;
    for (var i = 0 ; i < data.length; i++) {
        if (data.charAt(i) >= '0' && data.charAt(i) <= '9'){
            listOfNumbers = [listOfNumbers.slice(0, 0), data.charAt(i), listOfNumbers.slice(0)].join('');
        }
    }
    var originalKey = null;
  if (listOfNumbers.length > 15) 
    originalKey = listOfNumbers.substring(0,15);
  else
    originalKey = listOfNumbers;
  return Number(originalKey) + Number(password);
}

  render() {
    const { error, recording, deeplink, uid, nameLiveroom, thumbnail} = this.state;
    const { t } = this.props;

    if(nameLiveroom == null) {
      this.getLiveroomInfo();
    }

    if (error != null) {
      return (
        <div id="content">
          <div className="page-error">
            <div className="container text-center">
              <img src={require('../../assets/images/404.png').default} />
              <h2 className="heading">{t("recording_page.error_recording", "Lỗi bài thu")}</h2>
              <p>{t("recording_page.invalid_path", "Đường dẫn không hợp lệ")}</p>
              <a href="/" className="btn-yellow">
                <i className="fa fa-angle-left" /> {t("recording_page.back_home", "Quay về trang chủ")}
              </a>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <>
          <div id="content">
            <Helmet>
              <title>{recording != null && recording.song.songName}</title>
            </Helmet>
            <div className="record-detail-page">
              <div className="container">
                {!detectApp("mi") && (
                  <ToastDownloadApp></ToastDownloadApp>
                )}
                <div className="row">
                  <div className="col-md-8">
                    <div className="item-record pl-xl-4 containerOpenApp"
                      playsInline={true}>
                      {thumbnail != null ? 
                        (<img src={thumbnail} style={{width: '100%', height: 'auto'}}></img>)
                         : (<img src="https://www.yokara.com/react-source/static/media/social_icon_yokara.fcb9e0c2.jpg" style={{width: '100%', height: 'auto'}}></img>)
                      }
                        {deeplink != null ? (
                          <OpenApp href={deeplink}>
                            <button className='btnOpenApp'>Mở Ứng Dụng</button>
                          </OpenApp>
                        ) : (<span>Vui lòng đợi...</span>)}
                    </div><br/>
                    <div className="info-record pl-xl-4">
                      <div className="middle" style={{color:'chocolate'}}>
                        Mời bạn Mở Ứng Dụng để cùng vào hát Kara!<br/>
                        Tên phòng: {nameLiveroom}<br/>
                        ID phòng: {uid}<br/>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
  }
}