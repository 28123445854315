import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from "react-router-dom";
import MyContext from '../common/MyContext';
import { withTranslation } from 'react-i18next';
import { detectApp } from '../common/utils.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import LoginWithParam from '../pages/LoginWithParam/LoginWithParam';
import { setCookie } from '../common/cookie.js';
import axios from 'axios';



class Header extends Component {
	constructor(props) {
		super(props);
		this.openNav = this.openNav.bind(this);
		this.showBoxSearch = this.showBoxSearch.bind(this);
		const { t } = this.props;
		var menu = [
			{
				menuName: t("header.top_recordings", "Nhạc HOT"),
				menuUrl: '/top-recordings'
			},
			{
				menuName: t("header.top_duet_recordings", "Song ca"),
				menuUrl: '/top-duet-recordings'
			},
			{
				menuName: t("header.top_users", "Danh ca"),
				menuUrl: '/top-users'
			},

			// {
			// 	menuName: t("header.buy_icoin", "Nạp iCoin"),
			// 	menuUrl: '/buy-icoin'
			// },

			{
				menuName: t("header.upload_beat", "Đăng tải beat"),
				menuUrl: '/upload-beat'
			}
		];

		if (detectApp("okara") || detectApp("mi")) {
			menu.splice(4, 1);
			menu.splice(3, 1);
		}

		this.state = {
			menu: menu
		}

		//this.checkParam();


	}

	openNav() {
		$('#openNav, #header .icon_menu, body').toggleClass('active');

		if ($('#header .header-desktop .icon_menu_2 .open_nav').is(":hidden") 
		&& $('#header .header-mobile .icon_menu_2 .open_nav').is(":hidden")) {
			$('#header .header-desktop .icon_menu_2 .close_nav').addClass('d-none');
			$('#header .header-desktop .icon_menu_2 .open_nav').removeClass('d-none');

			$('#header .header-mobile .icon_menu_2 .close_nav').addClass('d-none');
			$('#header .header-mobile .icon_menu_2 .open_nav').removeClass('d-none');
		} else {
			$(' #header .header-desktop .icon_menu_2 .open_nav').addClass('d-none');
			$(' #header .header-desktop .icon_menu_2 .close_nav').removeClass('d-none');

			$('#header .header-mobile .icon_menu_2 .open_nav').addClass('d-none');
			$('#header .header-mobile .icon_menu_2 .close_nav').removeClass('d-none');
		}

		



		if (!$('#box-search-pc').is(":hidden")) {
			$('#box-search-pc').slideToggle();
			$('body').toggleClass('active');
		}

	}
	showBoxSearch() {
		$('#box-search-pc').slideToggle();
		$('body').toggleClass('active');
		if ($('#openNav').hasClass('active')) {
			$('#openNav, #header .icon_menu, body').toggleClass('active');
		}
	}

	showIconGetVipOkara() {
		const { user } = this.context;

		if ((user != null && !user.vipOkara && detectApp("okara"))) {
			return (
				<img
					data-toggle="modal"
					data-target="#modalGetVipOkara"
					style={{ "cursor": "pointer", "width": "75px" }}
					src={require('../assets/images/get_vip_okara.svg').default} />
			)
		}
	}

	showIconVipOkara() {
		const { user } = this.context;
		if (user != null && user.vipOkara && detectApp("okara")) {
			return (
				<img
					style={{ "position": "absolute", "bottom": "0", "left": "143px", "width": "30px" }}
					src={VipOkaraIcon}
				/>
			);
		}
	}

	render() {

		const { menu } = this.state;
		const { user } = this.context;
		const { t } = this.props;
		const path = window.location.pathname;
		
		return (
			<header id="header"
				className="menu-white">
				<div className="container">
					<div className="top-header">
						<div className="header-desktop d-none d-xl-block">
							<div className="row">
								<div className="col-xl-3">
									<div className="left d-flex align-items-center m-0">
										<div className= "icon_menu_2" onClick={this.openNav} >
											<div className='open_nav ' width={50}>
												<i className="fa-solid fa-bars"></i>
											</div>
											<div className='close_nav d-none'>
												<i className="fa-solid fa-xmark"></i>
											</div>
										</div>
										<div className="logo">
											<Link to="/" >
												{/* <img id="" src={IkaraLogo} /> */}
												<img id="" src={require('../assets/images/' + t("header.logo", "yokara-logo-header.png")).default} />
											</Link>
										</div>
									</div>
								</div>
								<div className="col-xl-7">
									<div className="menu">
										<ul>
											{
												menu.map((menuItem) => (
													<li key={menuItem.menuUrl} >
														<Link  to={menuItem.menuUrl} title={menuItem.menuName}>{menuItem.menuName}</Link>
													</li>
												))
											}
											{detectApp("ikarayokara") && (
												<li>
													<a href={"/news/"} title={t("header.news", "Tin tức")}>{t("header.news", "Tin tức")}</a>
												</li>
											)}			

										</ul>
									</div>
								</div>
								<div className="col-xl-2">
									<div className="accountInfo right d-flex justify-content-end align-items-center">
										{/* <div className="search">
											<a onClick={this.showBoxSearch}>
												<img src={require('../assets/images/icon_search.png')}/>
											</a>
										</div> */}
										{this.showIconGetVipOkara()}


										{!detectApp("mi") && (
											user != null ? (
												<div>
													<div className="user" >
														<Link to="/me" title={user.name}>
															<img src={user.profileImageLink} />
														</Link>
													</div>
													{this.showIconVipOkara()}
												</div>
											) : (
												<div className="user" >
													<a
														data-toggle="modal"
														data-target="#loginDialog"
														// to="/login" 
														title={t('header.not_logged', 'Chưa đăng nhập')}>
														<img src="http://www.ikara.co/img/facebook_user.jpg" />
													</a>
												</div>
											)
										)
										}
									</div>
								</div>
							</div>
						</div>
						<div className="row header-mobile d-xl-none align-items-center justify-content-between">
						{path == "/"  || ( user && user.level && user.level >3) ?
							<div className= "icon_menu_2" onClick={this.openNav} >
								<div className='open_nav'>
									<i className="fa-solid fa-bars"></i>
								</div>
								<div className='close_nav d-none'>
									<i className="fa-solid fa-xmark"></i>
								</div>
							</div>
	:""}
							<div className="logo ">
								<Link to="/" ><img src={require('../assets/images/' + t("header.logo", "yokara-logo-header.png")).default} /></Link>
							</div>
							<div className='icon_vip'>
								{(user != null && detectApp("okara")) && (
									(user.vipOkara) 
									? (<img
										className='float-right'
										style={{"width": "60px" }}
										src={require('../assets/images/vip_okara.svg').default}
									/>) 
									: (<img
										className='float-right'
										data-toggle="modal"
										data-target="#modalGetVipOkara"
										style={{ "cursor": "pointer", "width": "75px" }}
										src={require('../assets/images/get_vip_okara.svg').default} />)
								)}
							</div>

							{/* <div className="right">
								<a onClick={this.showBoxSearch}>
									<img src={require('../assets/images/icon_search.png')} />
								</a>
							</div> */}
						</div>
					</div>
				</div>
			</header>
		);
	}
}
Header.contextType = MyContext;

export default withTranslation()(Header)