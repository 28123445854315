import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/functions';

const configPRO = {
    apiKey: "AIzaSyCm5OlPGjDYLkj7v8eaBpXzDQDqCcr3xps",
    authDomain: "ikara4m.firebaseapp.com",
    databaseURL: "https://ikara4m.firebaseio.com",
    storageBucket: "ikara4m.appspot.com",
    messagingSenderId: "366287804041",
    projectId: "ikara4m"
};

const configDEV = {
    apiKey: "AIzaSyA-16C0-y-9up_hgV_k0yjAX_2PS5nYmiA",
    authDomain: "ikara4m-dev.firebaseapp.com",
    databaseURL: "https://ikara4m-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "ikara4m-dev",
    storageBucket: "ikara4m-dev.firebasestorage.app",
    messagingSenderId: "217676695089",
    appId: "1:217676695089:web:6a8ba2ef677812cdac3710",
    measurementId: "G-0ECG4DD0GD"
  };
const finalFirebaseConfig =  window.location.hostname.includes("ikara-development") || window.location.hostname.includes("localhost") ?  configDEV :configPRO ;

firebase.initializeApp(finalFirebaseConfig);
export default firebase;