import React, { Component } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

export default class WinRateFishHunterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      winrate: 0, // Giá trị winrate hiện tại
      newWinrate: '', // Giá trị winrate mới
      loading: false, // Trạng thái loading
    };
  }

  componentDidMount() {
    this.fetchWinrate();
  }

  // Lấy giá trị winrate hiện tại từ server
  fetchWinrate = async () => {
    this.setState({ loading: true });

    axios.post(global.config.apiDomain + '/rest/fishhunter/winrate/get')
      .then(res => {
        this.setState({
          winrate: res.data,
          loading: false
        });
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
        toast.error("Không thể lấy giá trị winrate")
      });
  };

  // Cập nhật giá trị winrate
  updateWinrate = async () => {
    const { newWinrate } = this.state;
    if (newWinrate === '') {
      toast.warning("Vui lòng nhập giá trị WinRate");
      return;
    }
    const parsedWinrate = parseFloat(newWinrate); // Chuyển đổi sang số thực
    if (isNaN(parsedWinrate) || parsedWinrate < 0 || parsedWinrate > 1) {
      toast.warning("Vui lòng nhập giá trị hợp lệ (từ 0 đến 1)");
      return;
    }
    axios.post(global.config.apiDomain + '/rest/fishhunter/winrate/set/' + newWinrate)
      .then(res => {
        if (res.data.status === "OK") {
          this.setState({
            winrate: res.data.data.winRate,
            loading: false
          });

          toast.success(res.data.message)
        } else {
          this.setState({
            loading: false
          });
          toast.error(res.data.message)
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
        });
        toast.error('Lỗi khi cập nhật giá trị winRate:', error);
      });
  };

  render() {
    const { winrate, newWinrate, loading } = this.state;

    return (
      <div style={styles.container}>
        <div style={styles.card}>
          <h1>Winrate Fish Hunter</h1>
          {loading && <p>Đang tải...</p>}
          {!loading && (
            <>
              <p>WinRate hiện tại: <strong>{winrate}</strong></p>
              <div>
                <label>
                  <h5>Thay đổi Winrate:</h5>
                  <input
                    type="number"
                    value={newWinrate}
                    onChange={(e) => this.setState({ newWinrate: e.target.value })}
                    placeholder="Nhập giá trị (0-1)"
                    step="0.01"
                    min="0"
                    max="1"
                    style={styles.input}
                  />
                  <button onClick={this.updateWinrate} style={styles.button}>
                    Cập nhật
                  </button>
                </label>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '70vh',
    fontFamily: 'Arial, sans-serif',
  },
  card: {
    backgroundColor: '#fff',
    padding: '20px',
    textAlign: 'center',
    // maxWidth: '400px',
    width: '100%',
  },
  input: {
    padding: '8px',
    margin: '10px 0',
    width: 'calc(100%)',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    marginTop: '10px',
  }
};
