import React, { Component } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import ZSClient from '../components/Zalo';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MyContext from '../common/MyContext'
import { withTranslation } from 'react-i18next';
import "../assets/css/login.css";
import { setCookie } from '../common/cookie.js';
import { detectApp, detectMob } from '../common/utils.js';

const $ = window.$;

class LoginDialog extends Component {
  constructor(props) {
    super(props)

    this.handleResponse = this.handleResponse.bind(this);
    this.handleError = this.handleError.bind(this);

  }

  componentDidMount() {
    // this.facebookInitialize();
    // const auth = getAuth();
    // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);

    // Khởi tạo SDK LaoID khi component đã được mount
    if (window.LaoIdSSO) {
      window.LaoIdSSO.init(
        'ea4907b5-dce9-4eaf-a31d-d20e5d30560d',
        'https://wap.okara.la/login/uniid-callback',
        true
      );
    }
  }

  handleResponse = (data) => {

    $("#loginDialog").modal("hide");

    let self = this;
    // if (data.tokenDetail != null && data.tokenDetail.accessToken != null) {
    if (data.accessToken != null) {
      console.log(data)
      // const json = JSON.stringify({ domain: window.location.hostname, accessToken: data.tokenDetail.accessToken });
      const json = JSON.stringify({ domain: window.location.hostname, accessToken: data.accessToken });

      axios.post(global.config.apiDomain + '/rest/loginuser/login', json, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res => {
        console.log("login")
        const { user } = res.data;
        if (user != null) {
          if (detectApp("tl.okara") || detectApp("localhost") || detectApp("mz.okara") || detectApp("ht.okara")) {
            if (user.phoneNumber == null) {
              self.context.updateUserTemp(user);
              $("#modalPhoneVetification").modal("show");
              return;
            }
          }
          const { history } = self.props;
          window.localStorage.setItem("login", true);
          window.localStorage.setItem("userId", user.facebookId);
          window.localStorage.setItem("profileImageLink", user.profileImageLink);
          window.localStorage.setItem("name", user.name);
          setCookie("login", "true", 1);
          setCookie("userId", user.facebookId, 1);
          setCookie("profileImageLink", user.profileImageLink, 1);
          setCookie("name", user.name, 1);

          if (history) {
            if (history.action == "PUSH") {
              history.goBack();
            } else {
              history.push('/');
            }
            self.context.updateUser(user);
          }
        }
      })
    }
  }

  handleError = (error) => {
    $("#loginDialog").modal("hide");

    this.setState({ error });
    console.log(error)
  }


  googleSuccess = (data) => {

    $("#loginDialog").modal("hide");

    const json = JSON.stringify({ domain: window.location.hostname, accessToken: data.tokenId });

    axios.post(global.config.apiDomain + '/rest/loginuser/loginGoogle', json, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      console.log("login")
      const { user } = res.data;
      if (user != null) {
        if (detectApp("tl.okara") || detectApp("localhost") || detectApp("mz.okara") || detectApp("ht.okara")) {
          if (user.phoneNumber == null) {
            this.context.updateUserTemp(user);
            $("#modalPhoneVetification").modal("show");
            return;
          }
        }

        const { history } = this.props;
        window.localStorage.setItem("login", true);
        window.localStorage.setItem("userId", user.facebookId);
        window.localStorage.setItem("profileImageLink", user.profileImageLink);
        window.localStorage.setItem("name", user.name);
        setCookie("login", "true", 1);
        setCookie("userId", user.facebookId, 1);
        setCookie("profileImageLink", user.profileImageLink, 1);
        setCookie("name", user.name, 1);

        if (history) {
          if (history.action == "PUSH") {
            history.goBack();
          } else {
            history.push('/');
          }
          this.context.updateUser(user);
        }
      }
    })
  }

  googleFailed = (error) => {
    $("#loginDialog").modal("hide");
    console.log(error)

  }

  zaloSuccess = (code) => {
    // const json = JSON.stringify({ domain: window.location.hostname, accessToken: data.tokenDetail.accessToken });
    const json = JSON.stringify({ domain: window.location.hostname, accessToken: code });

    axios.post(global.config.apiDomain + '/rest/loginuser/loginZalo', json, {
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => {
      console.log("login")
      const { user } = res.data;
      if (user != null) {
        const { history } = this.props;
        window.localStorage.setItem("login", true);
        window.localStorage.setItem("userId", user.facebookId);
        window.localStorage.setItem("profileImageLink", user.profileImageLink);
        window.localStorage.setItem("name", user.name);
        setCookie("login", "true", 1);
        setCookie("userId", user.facebookId, 1);
        setCookie("profileImageLink", user.profileImageLink, 1);
        setCookie("name", user.name, 1);

        if (history) {
          if (history.action == "PUSH") {
            history.goBack();
          } else {
            history.push('/');
          }
          this.context.updateUser(user);
        }

      }
      $("#loginDialog").modal("hide");
    })
  }


  facebookLoginButton(renderProps) {
    return (
      <div className="p-1">
        <button
          onClick={renderProps.onClick}
          // onClick={() => this.loginForFacebook()}
          className="btn w-100 facebook-login text-center">
          <span className="float-left login-icon">
          <img className="" src={require('../assets/images/facebook_icon.svg').default} />
          </span>
          <label className="text-white my-1">Facebook</label>
          <span className="login-icon float-right"></span>
        </button>
      </div>
    )
  }

  googleLoginButton(renderProps) {
    return (
      <div className="p-1">
        <button onClick={renderProps.onClick} className="btn w-100 google-login text-center">
          <span className="float-left login-icon">
          <img src={require('../assets/images/google_icon.svg').default} />
          </span>
          <label className="my-1">Google</label>
          <span className="login-icon float-right"></span>
        </button>
      </div>
    )
  }

  facebookInitialize() {
    const { t } = this.props;

    window.fbAsyncInit = function () {
      console.log(window.FB)
      window.FB.init({
        appId: t("commons.facebook_app_id", "370514506352628"),
        cookie: true,
        xfbml: true,
        version: 'v3.1'
      });
      window.FB.AppEvents.logPageView();
    };
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }

  loginForFacebook() {

    $("#loginDialog").modal("hide");

    let self = this;

    window.FB.login((response) => {
      console.log('submit login', response);
      if (response.authResponse) {
        const json = JSON.stringify({ domain: window.location.hostname, accessToken: response.authResponse.accessToken });

        axios.post(global.config.apiDomain + '/rest/loginuser/login', json, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res => {
          console.log("login")
          const { user } = res.data;
          if (user != null) {
            const { history } = self.props;
            window.localStorage.setItem("login", true);
            window.localStorage.setItem("userId", user.facebookId);
            window.localStorage.setItem("profileImageLink", user.profileImageLink);
            window.localStorage.setItem("name", user.name);
            setCookie("login", "true", 1);
            setCookie("userId", user.facebookId, 1);
            setCookie("profileImageLink", user.profileImageLink, 1);
            setCookie("name", user.name, 1);

            if (history) {
              if (history.action == "PUSH") {
                history.goBack();
              } else {
                history.push('/');
              }
              self.context.updateUser(user);
            }
          }
        })

      } else {

      }
    });
  }

  openPhoneDialog() {
    $("#loginDialog").modal("hide");
    // $("#modalPhoneVetification").modal("show");
    $("#modalPhoneVetification").modal("show");

  }

  render() {
    const { t } = this.props;
    const pathName = window.location.pathname;
    let show = pathName != "/login/zalo-callback" && pathName != "/login/uniid-callback";
    return (
      <div className="modal fade" id="loginDialog" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content ">
            {/* <div className="modal-header"> */}
            <button type="button" className="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            {/* </div> */}
            <div className="modal-body">
              <div className="d-flex justify-content-center">
                <div className="login-logo p-3 ">
                <img src={require("../assets/images/" + t('login_dialog.logo', "yokara_icon.png")).default} />
                </div>
              </div>
              <div className="text-center p-2">
                <p style={{ "fontSize": "16px" }}>{t("login_dialog.description", "Đăng nhập hoặc đăng ký tài khoản của bạn để có trải nghiệm tốt hơn")}</p>
              </div>
              {!detectApp('lo.okara') && !detectApp('my.okara') &&
                <FacebookLogin
                  appId={t("commons.facebook_app_id", "370514506352628")}
                  autoLoad={false}
                  fields="name,email,picture"
                  isMobile={false}
                  xfbml={true}
                  version="3.1"
                  redirectUri={window.location.href}
                  disableMobileRedirect={true}
                  callback={this.handleResponse}
                  render={(renderProps) => (
                    this.facebookLoginButton(renderProps)
                  )}
                />}

              {detectApp('ikarayokara') &&
                <GoogleLogin
                  clientId="366287804041-bmieenvk3ra7eerv8p67eiura87of7bo.apps.googleusercontent.com"
                  onSuccess={this.googleSuccess}
                  onFailure={this.googleFailed}
                  cookiePolicy={'single_host_origin'}
                  render={(renderProps) => (
                    this.googleLoginButton(renderProps)
                  )}
                ></GoogleLogin>
              }

              {detectApp('ikarayokara') && <div className="p-1">
                <a href={ZSClient.getLoginUrl()} className="btn w-100 zalo-login text-center">
                  <span className="float-left login-icon">
                  <img src={require('../assets/images/zalo_icon.svg').default} />
                  </span>
                  <label className="text-white my-1">Zalo</label>
                  <span className="login-icon float-right"></span>
                </a>
              </div>}

              {detectApp('lo.okara') && <div className="p-1">
                <a href={"https://laoid.net/login?client_id=ea4907b5-dce9-4eaf-a31d-d20e5d30560d&redirect_uri=https://wap.okara.la/login/uniid-callback&use_callback_uri=true"} className="btn w-100 uniid-login text-center">
                  <span className="float-left login-icon">
                    <img className="mt-1" src={require('../assets/images/uniid_icon.svg').default} />
                  </span>
                  <label className="text-white my-1">LaoID</label>
                  <span className="login-icon float-right"></span>
                </a>
              </div>}

              {detectApp('ht.okara') && detectMob() != "unknown" && <div className="p-1">
                <a href={"natcom://webgamenatcom?ref=" + encodeURIComponent("https://okara.natcom.com.ht/login/natcomid-callback") + "&isFromWeb=true"} className="btn w-100 uniid-login text-center">
                  <span className="float-left login-icon">
                  <img className="mt-1" src={require('../assets/images/uniid_icon.svg').default} />
                  </span>
                  <label className="text-white my-1">NatcomID</label>
                  <span className="login-icon float-right"></span>
                </a>
              </div>}

              {/* Myanmar */}

              { detectApp("my.okara") && <div className="p-1">
                <a href={"https://uid.com.la:9998/credentials/sign-in/oauth/consent?clientId=a3fe5e84-212a-4bb2-be5e-84212adbb2ac&redirectUri=" + encodeURIComponent("https://wap.okara.la/login/uniid-callback") + "&isFromWeb=true"} className="btn w-100 uniid-login text-center">                  <span className="float-left login-icon">
                <img className="mt-1" src={require('../assets/images/uniid_icon.svg').default} />
                  </span>
                  <label className="text-white my-1">UniID</label>
                  <span className="login-icon float-right"></span>
                </a>
              </div>}

              {(detectApp('tl.okara') || detectApp('mz.okara') || detectApp('localhost') || detectApp('ht.okara')) && ( 
                <div className="p-1">
                  <a
                    onClick={this.openPhoneDialog}
                    className="btn w-100 phone-login text-center">
                    <span className="float-left login-icon">
                    <img className="" src={require('../assets/images/phone_icon.svg').default} />
                    </span>
                    <label className="text-white my-1">Phone</label>
                    <span className="login-icon float-right"></span>
                  </a>
                </div>
              )}
              <div className="text-center mt-2 login-cancel"><span data-dismiss="modal"><strong>Cancel</strong></span></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

LoginDialog.contextType = MyContext;
export default withRouter(withTranslation()(LoginDialog))