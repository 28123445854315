import React, { Component } from 'react';
import axios from 'axios';
import $ from 'jquery';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import lodash from 'lodash';
import bindModel from '../../common/bindModel.js';
import { toast } from 'react-toastify';
import "./css/checkbox.css";
import FacebookIcon from "../../assets/images/facebook_icon.svg";


export default class PostFanpagePage extends Component {


    constructor(props) {
        super(props);

        this.state = {
            miniGame: null
        }

        this.uploadThumbnailQuestion = this.uploadThumbnailQuestion.bind(this);
        this.uploadThumbnailResult = this.uploadThumbnailResult.bind(this);
        this.updateMiniGame = this.updateMiniGame.bind(this);
    }

    componentDidMount() {
        this.getMiniGame();
    }

    getMiniGame() {
        axios.post(global.config.apiDomain + '/rest/miniGame').then(res => {

            let miniGame = res.data;
            miniGame.timePostQuestion = this.convertDateTime(new Date(miniGame.timePostQuestion).toLocaleString());
            miniGame.timePostResult = this.convertDateTime(new Date(miniGame.timePostResult).toLocaleString());
            this.setState({
                miniGame: miniGame
            });

            console.log(res.data)
        })
    }

    updateMiniGame() {
        this.setState({
            loading: true
        });

        if(this.state.miniGame.timePostQuestion.length <= 16) {
            this.state.miniGame.timePostQuestion += ":00";
        }
        if(this.state.miniGame.timePostResult.length <= 16) {
            this.state.miniGame.timePostResult += ":00";
        }

        axios.post(global.config.apiDomain + '/rest/miniGame/update', JSON.stringify(this.state.miniGame), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.setState({
                loading: false
            });
            if(res.data.status == "OK") {
                toast.success(res.data.message);
            } else {
                toast.warning(res.data.message);
            }
        }, error => {
            this.setState({
                loading: false
            });
        })
    }

    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    uploadThumbnailQuestion(event) {

        this.setState({
            loading: true
        });

        var { miniGame } = this.state;
        var target = event.target;
        var bucketName = "ikara-data/images/postFanPages";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    miniGame.thumbnailQuestion = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        miniGame: miniGame
                    });

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    uploadThumbnailResult(event) {

        this.setState({
            loading: true
        });

        var { miniGame } = this.state;
        var target = event.target;
        var bucketName = "ikara-data/images/postFanPages";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/jpeg";
        if (extension == "png") contentType = "image/png";

        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    console.log(data)
                    this.setState({
                        loading: false
                    });

                    miniGame.thumbnailResult = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    this.setState({
                        miniGame: miniGame
                    });

                }.bind(this),
            });
        }.bind(this);

        fileReader.readAsArrayBuffer(target.files[0]);

    }

    convertDateTime(value) {
        let arr = value.split(",");
        let arrDate = arr[1].trim().split("/");
        arrDate[1] = arrDate[1].length == 1 ? "0" + arrDate[1] : arrDate[1];
        arrDate[0] = arrDate[0].length == 1 ? "0" + arrDate[0] : arrDate[0];
        let date = arrDate[2] + "-" + arrDate[1] + "-" + arrDate[0];
        return date + "T" + arr[0].substring(0,5);

    }

    handleResponse = (data) => {
        console.log(data)
        if (data.accessToken != null) {
            axios.post(global.config.apiDomain + '/rest/miniGame/updatePageToken', data.accessToken, {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then(res => {
                if (res.data.status == "OK") {
                    toast.success(res.data.message);
                    // this.updateMiniGame();
                } else {
                    toast.warning(res.data.message);
                }
            })
        }
    }

    facebookLoginButton(renderProps) {
        return (
            <div>
                <button onClick={renderProps.onClick} className="btn w-25 facebook-login text-center">
                    <span className="float-left login-icon">
                        <img className="" src={require('../../assets/images/facebook_icon.svg').default} />
                    </span>
                    <label className="text-white my-1">Get access token</label>
                    <span className="login-icon float-right"></span>
                </button>
            </div>
        )
    }

    submitButton(renderProps) {
        return (
            <div className='text-center'>
                {this.state.loading ?
                    (
                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Xác nhận...
                        </button>
                    ) :
                    (
                        <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={renderProps.onClick}>Xác nhận</button>
                    )}
            </div>
        )
    }

    render() {

        const { miniGame } = this.state;
        const model = bindModel(this);

        return (
            <div id="content">
                <div className='container p-5'>
                    <h3 className="text-center">Đăng bài</h3>
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Status câu hỏi</label>
                        </div>
                        <div className="col-10">
                            <textarea
                                className="form-control"
                                type="text"
                                name="url"
                                rows={10}
                                {...model('miniGame.statusQuestion')}
                            />
                        </div>
                    </div>
                    
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Thời gian đăng câu hỏi</label>
                        </div>
                        <div className="col-10">
                            <input
                                className="form-control"
                                type="datetime-local"
                                name="url"
                                {...model('miniGame.timePostQuestion')}
                            />
                        </div>
                    </div>
                    <div id="thumbnailQuestion" className="row mb-2">
                        <div className="col-2">
                            <label>Hình ảnh câu hỏi</label>
                        </div>
                        <div className="col-10">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="inputGroupFile03"
                                onChange={this.uploadThumbnailQuestion} />
                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                        </div>
                    </div>
                    {miniGame != null && miniGame.thumbnailQuestion != null ? (
                        <div id="thumbnailResult" className="row mb-2">
                            <div className="col-2">

                            </div>
                            <div className="col-10">
                                <img width={500} src={miniGame.thumbnailQuestion} />

                            </div>
                        </div>
                    ) : ""}
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Status kết quả</label>
                        </div>
                        <div className="col-10">
                            <textarea
                                className="form-control"
                                type="text"
                                name="url"
                                rows={4}
                                {...model('miniGame.statusResult')}
                            />
                        </div>
                    </div>
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Thời gian đăng kết quả</label>
                        </div>
                        <div className="col-10">
                            <input
                                className="form-control"
                                type="datetime-local"
                                name="url"
                                {...model('miniGame.timePostResult')}
                            />
                        </div>
                    </div>
                    <div id="thumbnailResult" className="row mb-2">
                        <div className="col-2">
                            <label>Hình ảnh kết quả</label>
                        </div>
                        <div className="col-10">
                            <input
                                type="file"
                                className="custom-file-input"
                                id="inputGroupFile03"
                                onChange={this.uploadThumbnailResult} />
                            <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                        </div>
                    </div>
                    {miniGame != null && miniGame.thumbnailResult != null ? (
                        <div id="thumbnailResult" className="row mb-2">
                            <div className="col-2">

                            </div>
                            <div className="col-10">
                                <img width={500} src={miniGame.thumbnailResult} />

                            </div>
                        </div>
                    ) : ""}

                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Kết quả</label>
                        </div>
                        <div className="col-10">
                            <input
                                className="form-control"
                                type="text"
                                name="url"
                                rows={4}
                                {...model('miniGame.result')}
                            />
                        </div>
                    </div>
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>ID người trả lời đúng</label>
                        </div>
                        <div className="col-10">
                            <input
                                className="form-control"
                                type="number"
                                name="url"
                                {...model('miniGame.userId')}
                            />
                        </div>
                    </div>
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Phần thưởng (iCoin)</label>
                        </div>
                        <div className="col-10">
                            <input
                                className="form-control"
                                type="number"
                                name="url"
                                {...model('miniGame.iCoinPlus')}
                            />
                        </div>
                    </div>
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Đăng câu hỏi</label>
                        </div>
                        <div className="col-10">
                            <div className="onoffswitch">
                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" tabIndex="0" {...model('miniGame.isPostQuestion')} />
                                <label className="onoffswitch-label" htmlFor="myonoffswitch">
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div id="link" className="row mb-2">
                        <div className="col-2">
                            <label>Đăng kết quả</label>
                        </div>
                        <div className="col-10">
                            <div className="onoffswitch">
                                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch2" tabIndex="0" {...model('miniGame.isPostResult')} />
                                <label className="onoffswitch-label" htmlFor="myonoffswitch2">
                                    <span className="onoffswitch-inner"></span>
                                    <span className="onoffswitch-switch"></span>
                                </label>
                            </div>
                        </div>
                    </div>

                   

                    <div className="row">
                        <div className="col-2">

                        </div>
                        <div className="col-10">
                            <FacebookLogin
                                appId="670920597615866"
                                autoLoad={false}
                                fields="name,email,picture"
                                scope="public_profile,pages_manage_engagement,pages_manage_posts,pages_read_engagement,pages_show_list,pages_read_user_content,manage_pages,pages_read_engagement"
                                isMobile={false}
                                xfbml={true}
                                version="3.1"
                                disableMobileRedirect={true}
                                callback={this.handleResponse}
                                render={(renderProps) => (
                                    this.facebookLoginButton(renderProps)
                                )}
                            />
                        </div>
                    </div>

                    <div className='text-center'>
                        {/* <FacebookLogin
                            appId="370514506352628"
                            autoLoad={false}
                            fields="name,email,picture"
                            scope="public_profile,pages_manage_engagement,pages_manage_posts,pages_read_engagement,pages_show_list,pages_read_user_content,manage_pages,pages_read_engagement"
                            isMobile={false}
                            xfbml={true}
                            version="3.1"
                            disableMobileRedirect={true}
                            callback={this.handleResponse}
                            render={(renderProps) => (
                                this.submitButton(renderProps)
                            )}
                        /> */}
                        {this.state.loading ?
                            (
                                <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    Xác nhận...
                                </button>
                            ) :
                            (
                                <button style={{ width: "120px" }} type="button" className="btn btn-red" onClick={this.updateMiniGame}>Xác nhận</button>
                            )}
                    </div>
                </div>
            </div>
        );
    }
}