import React, { Component } from "react";
import axios from "axios";
import "./../../assets/css/newDevice.css";
import MidiPlayer from "midi-player-js";
import * as Tone from "tone";
import MIDISounds from "midi-sounds-react";

export default class MidiRecordingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      midiRecording: [],
      cursor: null,
      statusButtonNextPage: false,
      isLoading: false,
      isInitialLoading: true,
      error: null,
      searchKeyword: "",
      sortName: null,
      sortMidiAIGenTime: null,
      totalMidiFiles: 0,
    };
    this.midiSoundsRef = React.createRef();
    this.player = new MidiPlayer.Player();
    this.synth = new Tone.PolySynth().toDestination();
    this.getMidiRecordingList = this.getMidiRecordingList.bind(this);
    this.handleMidi = this.handleMidi.bind(this);
    this.handleSortNameChange = this.handleSortNameChange.bind(this);
    this.handleSortTimeChange = this.handleSortTimeChange.bind(this);
  }

  componentDidMount() {
    this.getMidiRecordingList();
    this.getTotalMidiFiles();
  }

  getMidiRecordingList = () => {
    const { isInitialLoading, searchKeyword, sortName, sortMidiAIGenTime } =
      this.state;

    if (!isInitialLoading) {
      this.setState({ isLoading: true });
    }
    let apiUrl;
    let requestData = {
      cursor: this.state.cursor,
      sortName: sortName,
      sortMidiAIGenTime: sortMidiAIGenTime,
    };
    console.log("cursor (before request):", this.state.cursor);
    if (searchKeyword.trim()) {
      apiUrl = `${global.config.apiDomain}/rest/midi-score/search-midi-youtube-song`;
      requestData.keyWord = searchKeyword;
    } else {
      apiUrl = `${global.config.apiDomain}/rest/midi-score/get-list-midi-youtube-song`;
    }

    axios({
      method: "post",
      url: apiUrl,
      data: requestData,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        let midiRecording = res.data.models;
        let status = midiRecording.length > 0;
        let newCursor = res.data.cursor;
        this.setState((prevState) => {
          return {
            midiRecording: prevState.midiRecording.concat(midiRecording),
            statusButtonNextPage: status,
            isLoading: false,
            isInitialLoading: false,
            error: null,
            cursor: newCursor,
          };
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          isInitialLoading: false,
        });
        console.error("Error:", error);
      });
  };

  stopMidiPlayback = async () => {
    try {
      this.player.stop();
      this.synth.releaseAll();
    } catch (error) {
      console.error(
        "Lỗi khi dừng phát lại hoặc giải phóng MIDI:",
        error.message
      );
    }
  };

  handleMidi = async (midiPath) => {
    console.log("handleMidi được gọi với đường dẫn:", midiPath);
    try {
      await this.stopMidiPlayback();
      const response = await fetch(midiPath);
      const arrayBuffer = await response.arrayBuffer();
      const midiArray = new Uint8Array(arrayBuffer);
      this.player.loadArrayBuffer(midiArray);

      this.player.on("midiEvent", (event) => {
        console.log("Sự kiện MIDI:", event);
        if (event.name === "Note on") {
          console.log("Note On Event:", event);
          const { noteNumber, velocity } = event;
          const noteFrequency = Tone.Frequency(
            noteNumber,
            "midi"
          ).toFrequency();
          this.synth.triggerAttackRelease(noteFrequency, "8n");
        }
      });

      this.player.play();
    } catch (error) {
      console.error("Error loading or playing MIDI file:", error);
    }
  };

  handleSearch = () => {
    this.setState({ isLoading: true, midiRecording: [], cursor: null }, () => {
      const { searchKeyword, sortName, sortMidiAIGenTime } = this.state;
      const isSearching = searchKeyword.trim().length > 0;
      let apiUrl = isSearching
        ? `${global.config.apiDomain}/rest/midi-score/search-midi-youtube-song`
        : `${global.config.apiDomain}/rest/midi-score/get-list-midi-youtube-song`;

      let requestData = {
        cursor: null,
        sortName: sortName,
        sortMidiAIGenTime: sortMidiAIGenTime,
      };

      if (isSearching) {
        requestData.keyWord = searchKeyword;
      }

      axios({
        method: "post",
        url: apiUrl,
        data: requestData,
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          this.setState({
            midiRecording: res.data.models,
            cursor: res.data.cursor,
            statusButtonNextPage: res.data.models.length > 0,
            isLoading: false,
          });
        })
        .catch((error) => {
          this.setState({ isLoading: false });
          console.error("Error:", error);
        });
    });
  };

  handleSortNameChange = (e) => {
    const newSortName = e.target.value;

    this.setState(
      {
        sortName: newSortName,
        midiRecording: [],
        cursor: null,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  handleSortTimeChange = (e) => {
    const newSortMidiAIGenTime = e.target.value;

    this.setState(
      {
        sortMidiAIGenTime: newSortMidiAIGenTime,
        midiRecording: [],
        cursor: null,
      },
      () => {
        this.handleSearch();
      }
    );
  };

  getTotalMidiFiles = () => {
    axios.get(`${global.config.apiDomain}/rest/midi-score/count-midi-recording`)
      .then(response => {
        this.setState({ totalMidiFiles: response.data });
      })
      .catch(error => {
        console.error("Lỗi khi lấy tổng số file MIDI:", error);
      });
  };

  render() {
    const {
      midiRecording,
      statusButtonNextPage,
      isLoading,
      isInitialLoading,
      searchKeyword,
      sortName,
      sortMidiAIGenTime,
      totalMidiFiles,
    } = this.state;

    return (
      <>
        <div id="content">
          <div className="container-fluid">
            <div className="container-fluid">
              <h1 className="text-center">Danh sách chấm điểm bài thu</h1>
            </div>

            <div className="search-container mb-4">
              <div className="d-flex align-items-center" style={{ width: '100%' }}>
                <div className="d-flex align-items-center flex-grow-1">
                  <div className="mr-3">
                    <label htmlFor="sortName" className="mr-2 mb-0">
                      Sắp xếp theo tên:
                    </label>
                    <select
                      id="sortName"
                      className="form-control form-control-sm"
                      style={{ width: "100px", display: "inline-block" }}
                      value={sortName || ""}
                      onChange={this.handleSortNameChange}
                    >
                      <option value="NEWEST">A-Z</option>
                      <option value="OLDEST">Z-A</option>
                    </select>
                  </div>

                  <div className="mr-3">
                    <label htmlFor="sortTime" className="mr-2 mb-0">
                      Sắp xếp theo thời gian:
                    </label>
                    <select
                      id="sortTime"
                      className="form-control form-control-sm"
                      style={{ width: "100px", display: "inline-block" }}
                      value={sortMidiAIGenTime || ""}
                      onChange={this.handleSortTimeChange}
                    >
                      <option value="NEWEST">Mới nhất</option>
                      <option value="OLDEST">Cũ nhất</option>
                    </select>
                  </div>

                  <div style={{ width: "300px" }}>
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Nhập từ khóa tìm kiếm..."
                        value={searchKeyword}
                        onChange={(e) =>
                          this.setState({ searchKeyword: e.target.value })
                        }
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          onClick={this.handleSearch}
                        >
                          Tìm kiếm
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ marginLeft: 'auto' }}>
                  {totalMidiFiles > 0 && (
                    <button 
                      className="btn btn-primary" 
                      disabled 
                      style={{ 
                        cursor: 'default',
                        opacity: 1
                      }}
                    >
                      {totalMidiFiles}
                    </button>
                  )}
                </div>
              </div>
            </div>

            {isInitialLoading ? (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            ) : midiRecording.length > 0 ? (
              <table className="table table-hover">
                <thead>
                  <tr className="text-center">
                    <th className="align-middle" scope="col">
                      STT
                    </th>
                    <th className="align-middle" scope="col">
                      Id
                    </th>
                    <th className="align-middle" scope="col">
                      Tên bài hát
                    </th>
                    <th className="align-middle" scope="col">
                      Thời gian
                    </th>
                    <th className="align-middle" scope="col">
                      Số file vocal
                    </th>
                    <th className="align-middle" scope="col">
                      File Midi
                    </th>
                    <th className="align-middle" scope="col">
                      Link nhạc
                    </th>
                    <th className="align-middle" scope="col">
                      Phát
                    </th>
                    <th className="align-middle" scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {midiRecording &&
                    midiRecording.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td className="align-middle text-center">
                            {index + 1}
                          </td>
                          <td className="align-middle text-center" scope="col">
                            {item.id}
                          </td>
                          <td className="align-middle text-center" scope="col">
                            {item.songName}
                          </td>
                          <td className="align-middle text-center" scope="col">
                            {item.midiAIGenTime && item.midiAIGenTime !== 0
                              ? new Date(item.midiAIGenTime).toLocaleString()
                              : ""}
                          </td>
                           <td className="align-middle text-center" scope="col">
                            {item.numVocalsGenMidi}
                          </td>
                          <td className="align-middle text-center" scope="col">
                            {item.midi}
                          </td>
                          <td className="align-middle text-center" scope="col">
                            <a
                              href={item.songUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Nghe nhạc
                            </a>
                          </td>
                          <td className="align-middle text-center" scope="col">
                            <div className="d-flex justify-content-center">
                              {item.midi && (
                                <button
                                  className="btn btn-primary mr-2"
                                  onClick={() => {
                                    console.log("click button");
                                    this.handleMidi(item.midi);
                                  }}
                                >
                                  Play
                                </button>
                              )}
                              {item.midi && (
                                <button
                                  className="btn btn-danger mr-2"
                                  onClick={() => {
                                    this.stopMidiPlayback();
                                  }}
                                >
                                  Stop
                                </button>
                              )}
                            </div>
                          </td>
                          <td className="align-middle text-center">
                            <div className="d-flex justify-content-center">
                              <button
                                className="btn btn-success ml-2 mr-2"
                                onClick={() =>
                                  window.open(
                                    `/admin/midi-recording/${item.id}`,
                                    "_blank"
                                  )
                                }
                              >
                                Danh sách bài thu có midi
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  <MIDISounds
                    ref={this.midiSoundsRef}
                    appElementName="root"
                    instruments={[3]}
                  />
                </tbody>
              </table>
            ) : (
              <div className="text-center">
                <p>Không có dữ liệu.</p>
              </div>
            )}
            {statusButtonNextPage && (
              <div
                onClick={this.getMidiRecordingList}
                className="view_more w-100 text-center mb-lg-4"
              >
                <a className="d-none d-md-block">
                  <span>{isLoading ? "ĐANG TẢI..." : "TẢI THÊM"}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}
