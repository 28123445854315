import React, { Component } from 'react'
import {Link} from "react-router-dom";
import Moment from 'react-moment';
import {withTranslation} from 'react-i18next';

class TopRecordingsItemInRecordingPage extends Component {
    render() {
        const {recording, t} = this.props;
        const {song, thumbnailImageUrl, onlineRecordingUrl, owner, recordingType, viewCounter, addDate} = recording;
        return (
            <div className="item">
                <div className="row">
                    <div className="col-6">
                        <Link to={'.' + onlineRecordingUrl}>
                            <img src={thumbnailImageUrl} width="100%" alt={song.songName} />
                        </Link>
                    </div>
                    <div className="col-6 pl-0 info">
                        <h3 className="title">
                            <Link to={'.' + onlineRecordingUrl}>
                                {song.songName}
                            </Link>
                        </h3>
                        <p className="view_num">{owner.name}</p>
                        {recordingType=="VIDEO" &&
                            <img src={require('../../../assets/images/icon-camera.svg').default} className="icon-camera" />
                        }
                        <p style={{fontSize: "14px"}} className="view_num">{viewCounter + " " + t("recording_page.view","lượt xem")}  - {" "} 
                        <Moment  unix fromNow tz="Asia/Ho_Chi_Minh" locale="en">
                            {addDate/1000}
                        </Moment>
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(TopRecordingsItemInRecordingPage)

