import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import MyContext from '../common/MyContext'
import { withTranslation } from 'react-i18next';
import { setCookie } from '../common/cookie.js';
import { detectApp } from '../common/utils.js';
import { toast } from 'react-toastify';
import bindModel from '../common/bindModel.js';
import PhoneVetification from "../assets/images/confirm_phone_vetification.svg";
const $ = window.$;

class ConfirmPhoneVetificationDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            otp: "",
            sending: false,
            second: 60,
            loading: false,
            counterSecond: null
        }

        this.confirmPhone = this.confirmPhone.bind(this);
        this.sendOTP = this.sendOTP.bind(this);
        this.reSendOTP = this.reSendOTP.bind(this);
    }

    componentDidMount() {
        $('#modalConfirmPhoneVetification').on('show.bs.modal', function (e) {
            this.counterSecond();
        }.bind(this));
    }

    counterSecond() {

        if (this.state.counterSecond != null && this.state.counterSecond != undefined) {
            clearInterval(this.state.counterSecond);
        }

        this.setState({
            second: 60,
            sending: true
        });

        this.state.counterSecond = setInterval(() => {
            let second = --this.state.second;
            this.setState({
                second: second
            })

            if (second <= 0) {
                clearInterval(this.state.counterSecond);
                this.setState({
                    sending: false
                })
            }
        }, 1000);
    }

    confirmPhone() {
        this.setState({
            loading: true
        });

        const { userTemp } = this.context;

        const json = {
            facebookId: userTemp ? userTemp.facebookId : null,
            phone: userTemp.phoneNumber,
            otp: this.state.otp,
            domain: window.location.hostname
        }

        axios.post(global.config.apiDomain + '/rest/okara/confirmPhoneVetification', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {

            this.setState({
                loading: false
            });

            if (res.data.status == "OK") {
                this.context.reLoginUser();
                this.setState({
                    otp: ""
                });
                $("#modalConfirmPhoneVetification").modal("hide");
            } else if (res.data.status == "REGISTER") {
                $("#modalConfirmPhoneVetification").modal("hide");
                $("#modalRegisterPhone").modal("show");
            } else {
                toast.warning(res.data.message);
            }
        });
    }

    sendOTP() {
        const { userTemp } = this.context;

        const json = {
            phone: userTemp.phoneNumber,
            facebookId: userTemp.facebookId,
            domain: window.location.hostname
        }

        axios.post(global.config.apiDomain + '/rest/okara/phoneVetification', JSON.stringify(json), {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            if (res.data.status == "OK") {

            } else {
                // toast.warning(res.data.message);
            }
        });
    }

    reSendOTP() {
        this.setState({
            second: 120,
            sending: true
        });

        this.sendOTP();

        const counterSecond = setInterval(() => {
            let second = --this.state.second;
            this.setState({
                second: second
            })

            if (second <= 0) {
                clearInterval(counterSecond);
                this.setState({
                    sending: false
                })
            }
        }, 1000);

    }

    loadUser() {
        const json = JSON.stringify({ domain: window.location.hostname });

        axios.post(global.config.apiDomain + '/rest/loginuser', json, {
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => {
            const { user } = res.data;
            window.localStorage.setItem("login", true);
            window.localStorage.setItem("userId", user.facebookId);
            window.localStorage.setItem("profileImageLink", user.profileImageLink);
            window.localStorage.setItem("name", user.name);
            setCookie("login", "true", 1);
            setCookie("userId", user.facebookId, 1);
            setCookie("profileImageLink", user.profileImageLink, 1);
            setCookie("name", user.name, 1);
            this.context.updateUser(user);
        })
    }

    render() {
        const model = bindModel(this);
        const { t } = this.props;
        return (
            <div className="modal fade" data-backdrop="static" id="modalConfirmPhoneVetification" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        {/* <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Xác thực số điện thoại</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div> */}
                        <button type="button" className="close d-flex justify-content-end mr-2" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>

                        <div className="modal-body">
                            <div className='p-4'>
                                <div className='text-center'>
                                <img src={require('../assets/images/confirm_phone_vetification.svg').default} />
                                </div>
                                <div className='text-center mt-3'>
                                    <p><strong>{t("confirm_phone_vetification_dialog.title_1", "Xác thực số điện thoại")}</strong></p>
                                    <p>{t("confirm_phone_vetification_dialog.title_2", "Nhập OTP nhận được từ tin nhắn")}</p>
                                </div>
                                <div className=''>
                                    <input {...model('otp')} className='form-control' placeholder={t("confirm_phone_vetification_dialog.input_otp", "Nhập OTP")} />
                                </div>
                                {this.state.sending ? (
                                    <div className='text-center mt-3'>
                                        <p>{t("confirm_phone_vetification_dialog.sending", "Nhập OTP")}... ({this.state.second})s </p>
                                    </div>
                                ) : (
                                    <div className='text-center mt-3'>
                                        <a onClick={this.reSendOTP}><u>{t("confirm_phone_vetification_dialog.resend_otp", "Gửi lại mã OTP")}</u> </a>
                                    </div>
                                )}

                                <div className='text-center mt-3'>
                                    {this.state.loading ?
                                        (
                                            <button className="btn btn-red w-100" type="button" disabled>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                {t("confirm_phone_vetification_dialog.confirm", "Xác nhận")}...
                                            </button>
                                        ) :
                                        (
                                            <button onClick={this.confirmPhone} type="button" className="btn btn-red w-100">{t("confirm_phone_vetification_dialog.confirm", "Xác nhận")}</button>
                                        )}

                                </div>
                            </div>

                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary">Xác thực</button>
                        </div> */}
                    </div>
                </div>
            </div>
        );
    }
}

ConfirmPhoneVetificationDialog.contextType = MyContext;
export default withTranslation()(ConfirmPhoneVetificationDialog)