import React, { Component } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import SVGA from 'svgaplayerweb'
import $ from 'jquery';
import bindModel from '../../../common/bindModel';
import { DateTimePickerComponent } from '@syncfusion/ej2-react-calendars';


export default class RealGiftCreateOrUpdate extends Component {

    constructor(props) {
        super(props);

        this.state = {
            model: null,
            loading: false,
        }
        this.uploadThumbnail = this.uploadThumbnail.bind(this);
        this.createOrUpdateRealGift = this.createOrUpdateRealGift.bind(this);
    }
    componentWillReceiveProps(props) {
        const { model } = props;

        if (model != null) {
            this.setState({
                model: model,
            })
        }
    }


    uploadThumbnail(event) {
        var target = event.target;

   //05Dec24 Check if the file size exceeds 1MB
    if (  event.target.files[0].size > 1048576) { // 1MB in bytes
        toast.warning("Hình ảnh không được vượt quá dung lượng 1Mb.");
        return;
    }

        console.log("target", target);
        if (target.files[0].size > 10495760) {
            toast.error("Kích thước file phải dưới 10mb.");
            return;
        }
        const { model } = this.state;

        this.setState({
            loading: true
        });

        var bucketName = "ikara-data/images/realGift";
        var extension = target.files[0].name.split('.').pop().toLowerCase();
        var keyName = this.guid() + "." + extension;
        var contentType = "image/svga";
        if (extension == "png") contentType = "image/svga";
        var fileReader = new FileReader();

        fileReader.onload = function () {
            var fd = new FormData();
            fd.append('file', target.files[0]);
            fd.append('bucketName', bucketName);
            fd.append('objectKey', keyName);
            fd.append('contentType', contentType)
            $.ajax({
                url: global.config.apiDomain + '/web.UploadFile',
                data: fd,
                processData: false,
                contentType: false,
                type: 'POST',
                success: function (data) {
                    if (target.id == 'url') {
                        model.url = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    } 
                    // else if (target.id == 'thumbnailUrl') {
                    //     model.thumbnailUrl = 'https://data4.ikara.co/data/minio/' + bucketName + '/' + keyName;
                    // }
                    this.setState({
                        loading: false
                    });

                    this.setState({
                        model: model
                    });
                }.bind(this)
            });
        }.bind(this);
        fileReader.readAsArrayBuffer(target.files[0]);
    }

    createOrUpdateRealGift() {
        let obj = { ...this.state.model };
        console.log(obj);
        this.setState({
            loading: true
        });
        axios.post(global.config.apiDomain + "/rest/realGift/createOrUpdate", obj, {
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(res => {
                console.log(res);

                this.setState({
                    loading: false
                });
                if (res.data && res.data.status == "OK") {
                    window.$("#modalCreate").modal("hide");
                    toast.success(res.data.message);
                    this.props.resetList();
                } else {
                    toast.error(res.data.message)
                }
            })
    }
    guid() {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
    render() {
        const bind = bindModel(this);
        const { model } = this.state;
        return (
            <div>
                <div className="modal fade" id="modalCreate" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalCenterTitle">{model && model.id ? "Cập nhật quà vật lí" : "Thêm mới quà vật lí"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">×</span>
                                </button>
                            </div>

                            <div className="modal-body">
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>ID</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.id')}
                                            placeholder="Nhập ID..."
                                        />
                                    </div>
                                </div>
                                <div id="id" className="row mb-2">
                                    <div className="col-3">
                                        <label>Tên quà</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="form-control mb-2"
                                            type="text"
                                            name="id"
                                            {...bind('model.name')}
                                            placeholder="Nhập tên quà..."
                                        />
                                    </div>
                                </div>
                                <div id="avatar" className="row mb-2">
                                    <div className="col-3">
                                        <label>Ảnh</label>
                                    </div>
                                    <div className="col-9">
                                        <input id="url"
                                            className="custom-file-input"
                                            type="file"
                                            onChange={this.uploadThumbnail} />
                                        <label className="custom-file-label ml-3 mr-3" htmlFor="inputGroupFile03">Choose file</label>
                                    </div>
                                </div>
                                {model && model.url ?
                                    <div id="reviewThumbnailUrl" className="row mb-2">
                                        <div className="col-3">
                                        </div>
                                        <div id="show-thumbnailUrl" className="col-9" style={{ maxHeight: "300px" }}>
                                            <img src={model.url} width="200px" />
                                        </div>
                                    </div> : ''}
                                <div id="description" className="row mb-2">
                                    <div className="col-3">
                                        <label>Mô tả</label>
                                    </div>
                                    <div className="col-9">
                                        <textarea class="form-control mb-2" type="text"
                                            {...bind('model.description')}
                                            placeholder="Nhập mô tả...">
                                        </textarea>
                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-red" data-dismiss="modal">Hủy</button>
                                {this.state.loading ?
                                    (
                                        <button style={{ width: "120px" }} className="btn btn-red" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Xác nhận...
                                        </button>
                                    ) :
                                    (
                                        <button style={{ width: "120px" }} type="button" className="btn btn-red"
                                            onClick={this.createOrUpdateRealGift}
                                        >Xác nhận</button>
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}