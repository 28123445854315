import React, { Component } from 'react'
import Moment from 'react-moment';
import 'moment-timezone';
import 'moment/locale/vi';
import MainCommentBox from './MainCommentBox';
import { Link } from 'react-router-dom';

import { withTranslation } from 'react-i18next';
import {detectMob} from '../../../common/utils.js';

class CommentLine extends Component {

    constructor(props) {
        super(props);
        this.toogle = this.toogle.bind(this);
    }

    toogle() {
        const { commentLv1Id } = this.props;

        if (this.props.commentLv1Id != null) {
            this.props.toggle(commentLv1Id);
        } else {
            this.props.toggle(this.props.comment.key);
        }

    }

    // addComment() {
    //     console.log('commentLine');
    // }

    render() {
        const { comment, recording, user, toggleComment, toggleCommentId} = this.props;

        const {t} = this.props;

        let userId = comment.userId;
        let fbLink = '#';
        let fbAvatar = comment.userProfile;
        let fbName = comment.userName;
        let message = comment.message;
        let commentId = comment.key;
        let startLuckyGiftTime = comment.startLuckyGift / 1000;
        let dateTime = comment.dateTime / 1000;
        let randomFbAvatar = "https://storage.googleapis.com/ikara-storage/randomavatar/" + (comment.userId % 100) + ".jpg"

        return (
            <div data-userid={userId} data-commentid={commentId} className="comment_level1 _3-8y _5nz1 clearfix" direction="left">
                <div style={{ width: "56px", height: "48px" }} className="_ohe lfloat">
                    <Link to={`/user/` + userId} src={fbAvatar}  className="img _8o _8s UFIImageBlockImage">
                        <img className="_1ci img" src={fbAvatar} alt="" onError={(e) => { e.target.onerror = null; e.target.src = randomFbAvatar }}></img>
                    </Link>
                </div>
                <div>
                    <div className="UFIImageBlockContent _42ef clearfix" direction="right">
                        <div className="_ohf rfloat">
                            <div>
                            </div>
                        </div>
                        <div >
                            <div>
                                <span>
                                    <Link to={`/user/` + userId}  className=" UFICommentActorName">
                                        {fbName}
                                    </Link>
                                </span>
                                <div className="_3-8m">
                                    <div className="_30o4">
                                        <span>
                                            <span className="_5mdd">
                                                <span>
                                                    
                                                    {comment.roomType == "LUCKYGIFT" ? (
                                                        comment.luckyGiftGaveStatus == "NEW"  ? (
                                                            <a href={detectMob() != "unknown" ? global.config.appName + "://recording?parameters=" + encodeURIComponent("{\"_id\":" + recording._id + ",\"recordingId\":\""+recording.recordingId+"\"}") : ""}><img style={{"width": "150px"}} src={message} /></a>
                                                        ) : (
                                                            <img style={{"width": "150px", "filter": "brightness(0.6)"}} src={message} />
                                                        )
                                                        
                                                    ) : (
                                                        message
                                                    )}
                                                    
                                                </span>
                                            </span>
                                        </span>
                                        <span>
                                        </span>
                                    </div>
                                </div>
                                <div className="_2vq9 fsm fwn fcg">

                                    <a style={{ color: "blue" }} onClick={this.toogle}
                                        {...(user != null ? { "data-toggle": "modal", "data-target": "#loginDialog" } : {})}>
                                        <em className="_4qba" data-intl-translation="Phản hồi" data-intl-trid="">
                                            {t("recording_page.reply", "Trả lời")}
                                        </em>
                                    </a>
                                    <span role="presentation" aria-hidden="true"> ·
                                    </span>
                                    <span>
                                        <abbr className="livetimestamp" data-utime="1481657364" data-shorten="true">
                                            <Moment unix fromNow tz="Asia/Ho_Chi_Minh" locale="en">
                                            {comment.roomType == "LUCKYGIFT" ? 
                                                    startLuckyGiftTime : 
                                                    dateTime
                                                }
                                            </Moment>
                                        </abbr>
                                    </span>
                                </div>
                            </div>
                        </div>
                        {
                            comment.childs != null ?
                                (comment.childs.map(e => (
                                    <CommentLine
                                        commentLv1Id={commentId}
                                        key={e.key}
                                        comment={e}
                                        recording={recording}
                                        user={user}
                                        toggleComment={toggleComment}
                                        toggleCommentId={toggleCommentId}
                                        toggle={this.toogle}
                                        t = {t}
                                    ></CommentLine>
                                ))) :
                                ('')
                        }

                        {
                            (toggleComment == true && toggleCommentId == commentId) ?
                                (<MainCommentBox
                                    recording={recording}
                                    user={user}
                                    commentLv1Id={commentId}
                                    addNewComment={this.props.addNewComment}
                                ></MainCommentBox>) :
                                ('')
                        }
                    </div>

                </div>
            </div>
        )
    }
}

export default withTranslation()(CommentLine)

