import React, { Component } from 'react';
import Moment from 'react-moment';
import { Share } from 'react-facebook';
import { withTranslation } from 'react-i18next';
import MyContext from '../../../common/MyContext.js'
import Firebase from '../../../components/Firebase.js'
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { FacebookProvider } from 'react-facebook';

import '../../../assets/css/recordinfo.css';
import { detectApp, detectMob } from '../../../common/utils.js';

class RecordingInfoInRecordingPage extends Component {

    constructor(props) {
        super(props)

        this.state = {
            liked: false,
            totalLike: 0,
            isFirst: true
        }

        this.ikaraNode = Firebase.database().ref("ikara");
        this.submitLike = this.submitLike.bind(this);
        this.loadLikes = this.loadLikes.bind(this);
        this.redirectToLogin = this.redirectToLogin.bind(this);


    }

    componentDidUpdate() {
        const { recording } = this.props;
        const { isFirst } = this.state;
        if (recording != null && isFirst == true) {
            this.state.isFirst = false;
            this.loadLikes();
        }
    }

    submitLike() {

        const { liked } = this.state;
        const { user } = this.context;
        const { recording } = this.props;

        if (!liked) {
            if (user != null) {
                this.setState({
                    liked: true
                });
                var json = {
                    userId: user.facebookId,
                    language: 'vi',
                    recordingId: recording._id
                }
                axios.post(global.config.apiDomain + '/rest/like', JSON.stringify(json), {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => {
                    console.log(res);
                });
            } else {
                // this.redirectToLogin();
                window.$('#loginDialog').modal('show')
            }
        }
    }


    loadLikes() {
        const { recording } = this.props;
        const { user } = this.context;

        var self = this;
        const { location } = window;
        // this.ikaraNode.child('recordingLikes').child(recording._id).orderByChild('dateTime').limitToLast(20).once('value').then(function(snapshot){
        //     snapshot.forEach(function(likeSnapshot) {
        //         var like = likeSnapshot.val();
        //         like.key = likeSnapshot.key;
        //         like.userProfile = like.userProfile.replace("http://", location.protocol+'//');
        //         if(!self.state.likes.some(o => o.key == like.key)) {
        //             self.state.likes.unshift(like);    
        //         }

        //     });
        //     self.setState({
        //         likes : self.state.likes,
        //     });
        // });

        this.ikaraNode.child('recordings').child(recording._id).child("likeCounter").on('value', function (snapshot) {
            if (snapshot.val() == null) {
                self.setState({
                    totalLike: 0
                })
            } else {
                self.setState({
                    totalLike: snapshot.val()
                })
            }

        });

        if (user != null) {
            this.ikaraNode.child('recordingLikes').child(recording._id).child(user.facebookId).once('value').then(function (snapshot) {
                if (snapshot.val() == null) {
                    self.setState({
                        liked: false
                    })
                } else {
                    self.setState({
                        liked: true
                    })
                }
            });
        }
    }


    redirectToLogin = () => {
        const { history } = this.props;
        if (history) history.push('/login');
    }

    renderSingButton() {
        const { user } = this.context;
        if((user != null)) {
            if(user.vipOkara) {
                return detectMob() == "Android" ? (
                    <div className='text-center p-2'>
                        <a href='https://play.google.com/store/apps/details?id=la.unitel.okara' id="sing-button" className='btn btn-red w-75'>ຮ້ອງເພງ</a>
                    </div>)
                 : (
                    <div className='text-center p-2'>
                        <a href='https://apps.apple.com/us/app/okara-laos-karaoke/id1331935480' id="sing-button" className='btn btn-red w-75'>ຮ້ອງເພງ</a>
                    </div>
                    );
            } else {
                return (
                    <div className='text-center p-2'>
                        <a id="sing-button" className='btn btn-red w-75' data-toggle="modal" data-target="#modalGetVipOkara">ຮ້ອງເພງ</a>
                    </div>);
            }
        } else {
            return (
                <div className='text-center p-2'>
                    <a id="sing-button" className='btn btn-red w-75' onClick={this.redirectToLogin}>ຮ້ອງເພງ</a>
                </div>);
        }


    }

    render() {
        const { t } = this.props;
        const { liked, totalLike } = this.state;
        const { user } = this.context;
        if (this.props.recording == null) {
            return (
                <></>
            )
        }
        const { song, message, viewCounter, addDate, vocalUrl, recordingId, onlineRecordingUrl, thumbnailImageUrl, onlineVocalUrl } = this.props.recording;
        return (
            <>
                <div className="recordingInfo">
                    <div className="detail-content">
                        <div className="detail-content-title">{song.songName}
                        </div>
                    </div>
                    <div className="detail-content-message">{t("recording_page.message", "Lời nhắn")}: {message !== null && message.trim() !== "" ? message : t("recording_page.message_value", "Tặng tất cả các bạn")}</div>
                    <div className="total-listen">
                        <img className="total-listen-icon" src={require('../../../assets/images/iconphone.png').default} />
                        <span >
                            {viewCounter} - </span>
                        <span>
                            <Moment format="DD/MM/YYYY" unix>
                                {addDate / 1000}
                            </Moment>
                        </span>
                        {/* <a className="actionButton" href="#"><i className="actionButtonIcon" id="buttonActionIcon_1" /><span className="actionButtonText">{t("singNow", "Hát ngay")}</span></a> */}

                        <a className="actionButton" href={vocalUrl}><i className="actionButtonIcon" id="buttonActionIcon_2" /><span className="actionButtonText">{t("recording_page.download", "Tải về")}</span></a>
                        <a className="actionButton" href={onlineVocalUrl}><i className="actionButtonIcon" id="buttonActionIcon_2" /><span className="actionButtonText">{t("recording_page.download_vocal", "Tải vocal")}</span></a>

                        <FacebookProvider
                            appId={t("commons.facebook_app_id", "370514506352628")}
                            isMobile={false}
                        >
                            <Share
                                href={global.config.apiDomain + "/" + onlineRecordingUrl}
                            >
                                {({ handleClick, loading }) => (
                                    <a iframe={thumbnailImageUrl} className="actionButton" onClick={handleClick}><i className="actionButtonIcon" id="buttonActionIcon_3" /><span className="actionButtonText">{t("recording_page.share", "Chia sẻ")}</span></a>
                                )}
                            </Share>
                        </FacebookProvider>
                        {!detectApp("mi") && (
                            <>
                            {liked ? (
                                <a
                                    className="actionButton">
                                    <i className="actionButtonIcon" id="buttonActionIcon_liked" /><span className="actionButtonText">{totalLike}</span>
                                </a>
                            ) : (
                                <a
                                    className="actionButton"
                                    onClick={this.submitLike}>
                                    <i className="actionButtonIcon" id="buttonActionIcon_like" /><span className="actionButtonText">{totalLike}</span>
                                </a>
                            )}
                            </>
                        )}
                        
                    </div>
                    
                    

                </div>
            </>
        )
    }
}
RecordingInfoInRecordingPage.contextType = MyContext;

export default withRouter(withTranslation()(RecordingInfoInRecordingPage))

