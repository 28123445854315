import React, { Component } from 'react';
import MyContext from '../common/MyContext';
import { Link } from "react-router-dom";
import axios from 'axios';
import $ from 'jquery';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router';
import { deleteAllCookies } from '../common/cookie.js';
import { detectApp } from '../common/utils.js';
import GetVipOkara from "../assets/images/get_vip_okara.svg";
import VipOkara from "../assets/images/vip_okara.svg";

class Menu extends Component {
	constructor(props) {
		super(props);
		this.logoutSubmit = this.logoutSubmit.bind(this);
	}

	logoutSubmit(event) {
		event.preventDefault();
		const json = JSON.stringify({ domain: window.location.hostname });
		let self = this;

		axios.post(global.config.apiDomain + '/rest/loginuser/logout', json, {
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => {
			window.localStorage.removeItem("login");
			window.localStorage.removeItem("userId");
			window.localStorage.removeItem("profileImageLink");
			window.localStorage.removeItem("name");
			this.props.history.push("/")

			deleteAllCookies();
		})
		self.context.updateUser(null);
		if ($('#openNav').hasClass('active')) {
			$('#openNav, #header .icon_menu, body').toggleClass('active');
		}
	}

	toggleMenu() {

		if ($('#header .header-desktop .icon_menu_2 .open_nav').is(":hidden")
			&& $('#header .header-mobile .icon_menu_2 .open_nav').is(":hidden")) {
			$('#header .header-desktop .icon_menu_2 .close_nav').addClass('d-none');
			$('#header .header-desktop .icon_menu_2 .open_nav').removeClass('d-none');

			$('#header .header-mobile .icon_menu_2 .close_nav').addClass('d-none');
			$('#header .header-mobile .icon_menu_2 .open_nav').removeClass('d-none');
		} else {
			$(' #header .header-desktop .icon_menu_2 .open_nav').addClass('d-none');
			$(' #header .header-desktop .icon_menu_2 .close_nav').removeClass('d-none');

			$('#header .header-mobile .icon_menu_2 .open_nav').addClass('d-none');
			$('#header .header-mobile .icon_menu_2 .close_nav').removeClass('d-none');
		}

		if ($('#openNav').hasClass('active')) {
			$('#openNav, #header .icon_menu, body').toggleClass('active');
		}
	}

	showIconGetVipOkara() {
		const { user } = this.context;

		if (user != null && !user.vipOkara && detectApp("okara")) {
			return (
				<img
					style={{ "cursor": "pointer" }}
					data-toggle="modal"
					data-target="#modalGetVipOkara"
					src={require('../assets/images/get_vip_okara.svg').default} />
			)
		}
	}

	showIconVipOkara() {
		const { user } = this.context;
		if (user != null && user.vipOkara && detectApp("okara")) {
			return (
				<img
					style={{ "position": "absolute", "bottom": "0", "left": "8px", "width": "30px" }}
					src={require('../assets/images/vip_okara.svg').default}
				/>
			);
		}
	}

	render() {
		const { t } = this.props;
		const { user } = this.context;
		let isActive = false;
		if (user != null) {
			if (user.role == "CUSTOMER-SUPPORTER" || user.role == "ICOIN-MANAGER" || user.role == "MANAGER" || user.role == "SELLER" || user.role == "SINGER" || user.role == "TESTER") {
				isActive = true;
			}
		}
		return (
			<div id="openNav">
				<div className="wrap">
					<div className="sidebar-account">
						{!detectApp("mi") && (
							user != null ? (
								<div className="top d-flex align-items-center pr-3">
									<div style={{ "position": "relative", "height": "45px" }}>
										<Link className='avatar' to="/me" onClick={this.toggleMenu}>
											<img src={user.profileImageLink} alt={user.name} />
										</Link>
										{this.showIconVipOkara()}
									</div>

									<div className="info">
										<h3><Link to="/me" onClick={this.toggleMenu}>{t("menu.hello", "Xin chào")}, {user.name}</Link>
										</h3>
										<p className='m-0'>{t("menu.your_account", "Tài khoản của bạn")}</p>
									</div>
									<div className='ml-auto'>
										{this.showIconGetVipOkara()}
									</div>
								</div>
							) : (
								<div className="top d-flex">
									<div className="avatar">
										<a
											// to="/login" 
											data-toggle="modal"
											data-target="#loginDialog"
											onClick={this.toggleMenu}>
											<img src="http://www.ikara.co/img/facebook_user.jpg" />
										</a>
									</div>
									<div className="info">
										<h3><a
											// to="/login" 
											onClick={this.toggleMenu}
											data-toggle="modal"
											data-target="#loginDialog"
										>{t("menu.login", "Đăng nhập tài khoản")}
										</a></h3>
										<p>{t("menu.you_are_not_logged", "Bạn chưa đăng nhập")}</p>
									</div>
								</div>
							)
						)
						}
						<div className="list_link">
							<ul>
								{user != null ? (
									<>
										<li>
											<Link to="/me" onClick={this.toggleMenu}>
												<span className="icon">
													<i className="fa-solid fa-address-card"></i>
												</span>
												<span>{t("menu.profile", "Hồ sơ")}</span>
												<i className="fa fa-angle-right" aria-hidden="true" />
											</Link>
										</li>
										<li className="d-md-none">
											<Link to="/my-gifts" onClick={this.toggleMenu}>
												<span className="icon">
													<i className="fa-solid fa-gift"></i>
												</span>
												<span>{t("menu.gift", "Quà tặng của tôi")}</span>
												<i className="fa fa-angle-right" aria-hidden="true" />
											</Link>
										</li>
										<li className="d-md-none">
											<Link to="/my-recordings" onClick={this.toggleMenu}>
												<span className="icon">
													<i className="fa-solid fa-circle-play"></i>
												</span>
												<span>{t("menu.recording", "Bài thu của tôi")}</span>
												<i className="fa fa-angle-right" aria-hidden="true" />
											</Link>
										</li>
										<li className="d-md-none">
											<Link to="/my-images" onClick={this.toggleMenu}>
												<span className="icon">
													<i className="fa-solid fa-images"></i>
												</span>
												<span>{t("menu.image", "Hình ảnh của tôi")}</span>
												<i className="fa fa-angle-right" aria-hidden="true" />
											</Link>
										</li>
									</>
								) : <></>}
								{/* {!detectApp("okara") &&  { user } && user && user.level > 3 ? (
									<li className="d-md-none">
										<Link to="/buy-icoin" onClick={this.toggleMenu}>
											<span className="icon">
												<i className="fa-solid fa-hand-holding-dollar"></i>
											</span>
											<span>{t("menu.buy_icoin", "Nạp iCoin")}</span>
											<i className="fa fa-angle-right" aria-hidden="true" />
										</Link>
									</li>
								) : <></>} */}

								<li>
									<Link to="/top-recordings" onClick={this.toggleMenu}>
										<span className="icon">
											<i className="fa-solid fa-music"></i>
										</span>
										<span>{t("menu.top_recordings", "Nhạc HOT")}</span>
										<i className="fa fa-angle-right" aria-hidden="true" />
									</Link>
								</li>
								<li>
									<Link to="/top-users" onClick={this.toggleMenu}>
										<span className="icon">
											<i className="fa-solid fa-user-tie"></i>
										</span>
										<span>{t("menu.top_users", "Danh ca")}</span>
										<i className="fa fa-angle-right" aria-hidden="true" />
									</Link>
								</li>
								<li>
									<Link to="/top-duet-recordings" onClick={this.toggleMenu}>
										<span className="icon">
											<i className="fa-solid fa-user-group"></i>
										</span>
										<span>{t("menu.top_duet_recordings", "Song ca")}</span>
										<i className="fa fa-angle-right" aria-hidden="true" />
									</Link>
								</li>
								{detectApp("ikarayokara") ? (
									<li>
										<Link to="/upload-beat" onClick={this.toggleMenu}>
											<span className="icon">
												<i className="fa-solid fa-cloud-arrow-up"></i>
											</span>
											<span>{t("menu.upload_beat", "Đăng tải beat")}</span>
											<i className="fa fa-angle-right" aria-hidden="true" />
										</Link>
									</li>
								) : <></>}

								{detectApp("ikarayokara") ? (
									<li>
										<a href="/news/" onClick={this.toggleMenu}>
											<span className="icon">
												<i className="fa-solid fa-newspaper"></i>
											</span>
											<span>{t("menu.news", "Tin tức")}</span>
											<i className="fa fa-angle-right" aria-hidden="true" />
										</a>
									</li>
								) : <></>}

								{(user != null && user.isAdmin && isActive) ? (
									<>
										<li>
											<Link to="/admin/manager-page" onClick={this.toggleMenu}>
												<span className="icon">
													<i className="fa-solid fa-briefcase"></i>
												</span>
												<span className='text-danger'>Trang quản lí</span>
												<i className="fa fa-angle-right" aria-hidden="true" />
											</Link>
										</li>
									</>
								) : <></>}

								{!detectApp("mz.okara") && (
									<li>
										<Link to="/privacy-policy" onClick={this.toggleMenu}>
											<span className="icon">
												<i className="fa-solid fa-lock"></i>
											</span>
											<span>{t("menu.privacy_policy", "Chính sách bảo mật")}</span>
											<i className="fa fa-angle-right" aria-hidden="true" />
										</Link>
									</li>
								)}


								<li>
									<Link to="/term" onClick={this.toggleMenu}>
										<span className="icon">
											<i className="fa-solid fa-lock"></i>
										</span>
										<span>{t("menu.term", "Điểu khoản dịch vụ")}</span>
										<i className="fa fa-angle-right" aria-hidden="true" />
									</Link>
								</li>
								{user != null ? (
									<li>
										<a onClick={this.logoutSubmit}>
											<span className="icon">
												<i className="fa-solid fa-right-from-bracket"></i>
											</span>
											<span>{t("menu.logout", "Đăng xuất")}</span>
										</a>
									</li>
								) : <></>}
							</ul>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

Menu.contextType = MyContext;

export default withRouter(Menu);