import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

const $ = window.$;

const ModalLoginInfo = ({ facebookId, lastAccess }) => {
    const [loading, setLoading] = useState(false);
    const [loginHistory, setLoginHistory] = useState([]);
    const [currentDate, setCurrentDate] = useState(() => {
        if (lastAccess) {
            return new Date(lastAccess);
        }
        return new Date();
    });
    const [firstLoginDate, setFirstLoginDate] = useState(null);
    const [lastLoginDate, setLastLoginDate] = useState(null);

    // Effect for modal show
    useEffect(() => {
        $('#modalLoginInfo').on('show.bs.modal', () => {
            if (facebookId) {
                fetchLoginHistory();
            }
        });
    }, [facebookId]);

    // Effect for month change
    useEffect(() => {
        if (facebookId) {
            fetchLoginHistory();
        }
    }, [currentDate, facebookId]);

    const fetchLoginHistory = async () => {
        setLoading(true);
        try {
            const response = await axios.post(global.config.apiDomain + '/rest/admin/getLoginInfo', {
                facebookId: facebookId,
                month: currentDate.getMonth() + 1,
                year: currentDate.getFullYear()
            });

            if (response.data.status === "OK") {
                const loginDates = response.data.data.list;
                setLoginHistory(loginDates);
                
                // Find first and last login dates
                if (loginDates.length > 0) {
                    setFirstLoginDate(Math.min(...loginDates));
                    setLastLoginDate(Math.max(...loginDates));
                } else {
                    setFirstLoginDate(null);
                    setLastLoginDate(null);
                }
            } else {
                toast.warning(response.data.message);
            }
        } catch (error) {
            toast.error("Error fetching login history");
        } finally {
            setLoading(false);
        }
    }

    const getDayClassName = (date) => {
        if (!date) return 'bg-light';
        
        const dayOfMonth = date.getDate();
        if (!loginHistory.includes(dayOfMonth)) return '';
        
        if (dayOfMonth === firstLoginDate) return 'bg-danger text-white';
        if (dayOfMonth === lastLoginDate) return 'bg-warning text-dark';
        return 'bg-primary text-white';
    }

    const isLoginDate = (date) => {
        const dayOfMonth = date.getDate();
        return loginHistory.includes(dayOfMonth);
    }

    const getDaysInMonth = (date) => {
        const year = date.getFullYear();
        const month = date.getMonth();
        const daysInMonth = new Date(year, month + 1, 0).getDate();
        const firstDayOfMonth = new Date(year, month, 1).getDay();
        const days = [];

        // Add empty cells for days before the first day of the month
        for (let i = 0; i < firstDayOfMonth; i++) {
            days.push(null);
        }

        // Add days of the month
        for (let i = 1; i <= daysInMonth; i++) {
            days.push(new Date(year, month, i));
        }

        // Add empty cells at the end to complete the last week
        const totalDays = days.length;
        const remainingDays = 7 - (totalDays % 7);
        if (remainingDays < 7) { // Only add if we don't have a complete week
            for (let i = 0; i < remainingDays; i++) {
                days.push(null);
            }
        }

        return days;
    }

    const previousMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1));
    }

    const nextMonth = () => {
        setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1));
    }

    const days = getDaysInMonth(currentDate);
    const monthNames = ["Tháng 1", "Tháng 2", "Tháng 3", "Tháng 4", "Tháng 5", "Tháng 6",
        "Tháng 7", "Tháng 8", "Tháng 9", "Tháng 10", "Tháng 11", "Tháng 12"
    ];

    return (
        <div>
            <div className="modal fade" id="modalLoginInfo" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Lịch sử đăng nhập</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {loading ? (
                                <div className="text-center">
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>
                            ) : (
                                <div className="p-4">
                                    <div className="d-flex justify-content-between align-items-center mb-3">
                                        <button className="btn btn-outline-primary" onClick={previousMonth}>
                                            <i className="fa fa-chevron-left"></i>
                                        </button>
                                        <h4>{monthNames[currentDate.getMonth()]} {currentDate.getFullYear()}</h4>
                                        <button className="btn btn-outline-primary" onClick={nextMonth}>
                                            <i className="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                    <div className="border rounded">
                                        <div className="d-flex bg-light border-bottom">
                                            <div className="flex-fill text-center p-3 fw-bold">Sun</div>
                                            <div className="flex-fill text-center p-3 fw-bold">Mon</div>
                                            <div className="flex-fill text-center p-3 fw-bold">Tue</div>
                                            <div className="flex-fill text-center p-3 fw-bold">Wed</div>
                                            <div className="flex-fill text-center p-3 fw-bold">Thu</div>
                                            <div className="flex-fill text-center p-3 fw-bold">Fri</div>
                                            <div className="flex-fill text-center p-3 fw-bold">Sat</div>
                                        </div>
                                        <div className="d-flex flex-wrap bg-white">
                                            {days.map((day, index) => (
                                                <div 
                                                    key={index} 
                                                    className={`flex-fill text-center p-3 border-bottom border-end ${day ? (isLoginDate(day) ? getDayClassName(day) : '') : 'bg-light'}`}
                                                    style={{ minWidth: '14.28%', height: '40px' }}
                                                >
                                                    {day ? day.getDate() : ''}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-red" data-dismiss="modal">Đóng</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ModalLoginInfo;