import axios from 'axios';

const API_BASE_URL = `${global.config.apiDomain}/rest/manage-vietcombank`;

const headers = {
  "Content-Type": "application/json"
};

const handleResponse = (response) => {
  if (!response.data) {
    throw new Error('No data received from server');
  }
  return response.data;
};

const handleError = (error) => {
  const message = error.response?.data?.message || error.message || 'An error occurred';
  throw new Error(message);
};

export const vietcomBankService = {
  getAccounts: async () => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/get-vietcombank-account`,
        {},
        { headers }
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  },

  createOrUpdateAccount: async (accountData) => {
    try {
      if (!accountData.accountNumber || !accountData.username || !accountData.propType) {
        throw new Error('Missing required fields');
      }
      const response = await axios.post(
        `${API_BASE_URL}/update`,
        accountData,
        { headers }
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  },

  deleteAccount: async (accountId) => {
    try {
      if (!accountId) {
        throw new Error('Account ID is required');
      }
      const response = await axios.post(
        `${API_BASE_URL}/delete`,
        { id: accountId },
        { headers }
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  },

  deleteAccounts: async (accountIds) => {
    try {
      if (!accountIds || accountIds.length === 0) {
        throw new Error('Account IDs are required');
      }
      const response = await axios.post(
        `${API_BASE_URL}/delete`,
        { ids: accountIds },
        { headers }
      );
      return handleResponse(response);
    } catch (error) {
      return handleError(error);
    }
  }
}; 