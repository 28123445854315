import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default class YoutubeMidiPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      songs: [],
      cursor: null,
      searchCursor: null,
      loading: false,
      statusButtonLoadPage: false,
      filterStatus: "ALL",
      sortOrder: "NEWEST",
      searchKeyword: "",
    };

    this.getSongs = this.getSongs.bind(this);
    this.changeStatusProcessing = this.changeStatusProcessing.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
  }

  componentDidMount() {
    this.getSongs();
  }

  getSongs(reset = false, isSearch = false) {
    const { loading, filterStatus, sortOrder, searchKeyword } = this.state;

    if (!loading) {
      this.setState({
        loading: true,
        ...(reset && { songs: [], cursor: null }),
      });

      if (isSearch && searchKeyword && searchKeyword.trim()) {
        // JSON riêng cho tìm kiếm
        let jsonSearch = {
          keyWord: searchKeyword,
          cursor: reset ? null : this.state.searchCursor,
        };

        axios.post(`${global.config.apiDomain}/rest/youtubemidi/searchBeats`, jsonSearch)
          .then((res) => {
            this.setState({
              songs: reset
                ? res.data.songs
                : this.state.songs.concat(res.data.songs),
              searchCursor: res.data.cursor,
              statusButtonLoadPage: res.data.cursor !== "END",
              loading: false,
            });
          });
      } else {
        let json = {
          cursor: reset ? null : this.state.cursor,
          midiStatus: filterStatus === "ALL" ? null : filterStatus,
          sortOrder: sortOrder,
        };

        axios
          .post(global.config.apiDomain + "/rest/youtubemidi/list", json)
          .then((res) => {
            this.setState({
              songs: reset
                ? res.data.songs
                : this.state.songs.concat(res.data.songs),
              cursor: res.data.cursor,
              statusButtonLoadPage: res.data.cursor !== "END",
              loading: false,
            });
          });
      }
    }
  }

  renderSortOptions() {
    const { sortOrder, loading } = this.state;

    return (
      <div className="sort-container mb-3">
        <label htmlFor="sortOrder" className="me-2">Sắp xếp: </label>
        <select
          id="sortOrder"
          value={sortOrder}
          onChange={(e) => {
            this.setState({ sortOrder: e.target.value }, () => {
              this.getSongs(true);
            });
          }}
          className="form-select w-auto"
          disabled={loading} ải
        >
          <option value="NEWEST">Mới nhất</option>
          <option value="OLDEST">Cũ nhất</option>
        </select>
      </div>
    );
  }

  changeStatusProcessing(recordingId) {
    axios.post(global.config.apiDomain + '/rest/youtubemidi/processing/' + recordingId)
      .then((res) => { });
  }
  cancelProcessing(recordingId) {
    axios.post(global.config.apiDomain + '/rest/youtubemidi/cancelProcessing/' + recordingId)
      .then((res) => {
        if (res.data.status === "OK") {
          this.setState((prevState) => ({
            songs: prevState.songs.map(song =>
              song.videoId === recordingId
                ? { ...song, midiStatus: 'NEW' }
                : song
            )
          }));
        }
      })
      .catch(err => {
        console.error("Error canceling processing:", err);
      });
  }

  getStatus(status) {
    switch (status) {
      case 'NEW':
        return <i className="fa-solid fa-ban fa-xl text-danger"></i>
      case 'PROCESSED':
        return <i class="fa-solid fa-circle-check fa-xl text-success"></i>
      case 'PROCESSING':
        return <i class="fas fa-cog fa-spin fa-xl text-primary"></i>
      // case 'REVERTED':
      //     return <i className="fa-solid fa-refresh fa-xl text-secondary"></i>
      // case 'CANCELED':
      //   return <i className="fa-solid fa-trash fa-xl text-secondary"></i>
      default:
        return <i className="fa-solid fa-ban fa-xl text-danger"></i>;
    }
  }
  handleSearchChange(e) {
    this.setState({ searchKeyword: e.target.value });
  }

  handleSearchClick = () => {
    this.getSongs(true, true);
  };

  renderSearchInput() {
    return (
      <div className="search-container mb-3 d-flex align-items-center">
        <label htmlFor="searchKeyword" className="me-2">
          Tìm kiếm Beat:
        </label>
        <input
          id="searchKeyword"
          type="text"
          className="form-control w-auto me-2"
          placeholder="Nhập tên Beat"
          value={this.state.searchKeyword}
          onChange={this.handleSearchChange}
        />
        <button className="btn btn-primary" onClick={this.handleSearchClick}>
          Tìm kiếm
        </button>
      </div>
    );
  }

  renderFilterOptions() {
    const { loading, filterStatus } = this.state;

    return (
      <div className="filter-container mb-3">
        <label htmlFor="filterStatus" className="me-2">
          Lọc theo trạng thái:{" "}
        </label>
        <select
          id="filterStatus"
          value={filterStatus}
          onChange={(e) => {
            this.setState({ filterStatus: e.target.value }, () => {
              this.getSongs(true);
            });
          }}
          className="form-select w-auto"
          disabled={loading}
        >
          <option value="ALL">Tất cả</option>
          <option value="NEW">Chưa xử lý</option>
          <option value="PROCESSING">Đang xử lý</option>
          <option value="PROCESSED">Đã xong</option>
          {/* <option value="CANCELED">Đã hủy</option> */}
        </select>
      </div>
    );
  }

  render() {
    const { songs, statusButtonLoadPage, loading, searchKeyword } = this.state;

    return (
      <>
        <div id="content">
          <div className="container-fluid">
            <h1 className="text-center">Danh sách Beat</h1>
            <div className="row mb-3">
              <div style={{ marginRight: "10px" }}>
                {this.renderFilterOptions()}
              </div>
              <div style={{ marginRight: "10px" }}>
                {this.renderSortOptions()}
              </div>
              <div>{this.renderSearchInput()}</div>
            </div>

            <table className="table table-hover">
              <thead>
                <tr>
                  <th scope="col">STT</th>
                  <th scope="col">UID</th>
                  <th scope="col">Trạng thái</th>
                  <th scope="col">Thời gian</th>
                  <th scope="col">VideoID</th>
                  <th scope="col">Thời gian Upload Midi</th>
                  <th scope="col">Tên Beat</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {songs && songs.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className="align-middle">{index + 1}</td>
                      <td className="align-middle">#{item.uid}</td>
                      <td className="align-middle">{this.getStatus(item.midiStatus)}</td>
                      <td className="align-middle">{new Date(item.addTime).toLocaleString()}</td>
                      <td className="align-middle"><strong>{item.videoId}</strong></td>
                      <td className="align-middle">
                        {item.uploadMidiTime && item.uploadMidiTime !== 0
                          ? new Date(item.uploadMidiTime).toLocaleString()
                          : ''}
                      </td>
                      <td className="align-middle">{item.songName}</td>
                      <td className="align-middle" style={{ width: "100px" }}>
                        <Link to={'/admin/youtube-midi/detail/' + item.videoId}>
                          <button
                            onClick={() => this.changeStatusProcessing(item.videoId)}
                            className='btn btn-success'
                          >
                            Xử lý
                          </button>
                        </Link>

                      </td>
                      {item.midiStatus === "PROCESSING" && (
                        <td>
                          <button
                            className="btn btn-danger"
                            onClick={() => this.cancelProcessing(item.videoId)}
                          >
                            Hủy xử lý
                          </button>
                        </td>
                      )}

                    </tr>
                  )
                })}
              </tbody>
            </table>
            {
              statusButtonLoadPage ?
                (<div           onClick={() => this.getSongs(false, !!searchKeyword)} className="view_more w-100 text-center mb-lg-4">
                  <a className="d-none d-md-block">
                    <span>{loading ? "ĐANG TẢI ..." : "TẢI THÊM"}</span>
                  </a>
                  <a className="view_more_mobile d-md-none">
                    <i className="fa fa-angle-down" aria-hidden="true" />
                  </a>
                </div>) :
                ('')
            }
          </div>
        </div>
      </>
    );
  }
}
