import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init


var lng = "vi";
const hostName = window.location.hostname
if(hostName.includes("yokara.com")) {
  lng = "en_yokara";
} else if(hostName.includes(".okara.co") 
|| hostName.includes("okara.natcom.com.ht")){
  lng = "ht_okara"; 
} else if(hostName.includes(".myanmar.co")){ //myanmar
  lng = "my_okara"; 
} else if(hostName.includes("okara.la")) {
  lng = "lo_okara";
} else if(hostName.includes("okara.tls")) {
  lng = "tl_okara";
} else if(hostName.includes("mkaraoke.co") || (hostName.includes("mz.ikara.co"))) {
  lng = "mz_okara";
} else if(hostName.includes("dev.ikara.co")) {
  lng = "en_yokara_dev";
} else if(hostName.includes("ikara.co")) {
  lng = "vi";
} else if(hostName.includes("mikara.org")) {
  lng = "mi";
} else if(hostName.includes("ikara-development.appspot.com")) {
  lng = "en_yokara_dev";
} 


i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "vi",
    debug: true,
    lng: lng,
    backend : {
      loadPath: window.location.port.includes("3000") ? "/locales/{{lng}}/{{ns}}.json" : "/react-source/locales/{{lng}}/{{ns}}.json"
    },

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  });


export default i18n;