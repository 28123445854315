import React, { Component, useEffect } from "react";
import axios from "axios";
import $ from 'jquery';
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default class AdminPage extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                <div id="content">
                    <div className="container-fluid">
                        <h1 className="text-center">Quản lí chức năng Admin</h1>
                        <hr />
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col" style={{ paddingLeft: "40px" }}>Tính năng</th>
                                    <th scope="col" style={{ paddingLeft: "40px" }}>Marketing</th>
                                    <th scope="col" style={{ paddingLeft: "40px" }}>Mục Khác</th>
                                </tr>
                            </thead>
                            <tbody className="">
                                <tr>
                                    <th>1</th>
                                    <td>
                                        <div>
                                            <div class="dropright ml-4">
                                                <i class="fa-solid fa-shop mr-2"></i>
                                                <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                    Quản lí Shop
                                                </a>
                                                <div class="dropdown-menu table-secondary">
                                                    <Link to="/admin/itemShop-manager" className="dropdown-item">
                                                        Quản lí shop khung avatar
                                                    </Link>
                                                    <Link to="/admin/itemShopCommentFrame-manager" className="dropdown-item">
                                                        Quản lí shop khung bình luận
                                                    </Link>
                                                    <Link to="/admin/itemShopRoomEntryEffect-manager" className="dropdown-item">
                                                        Quản lí shop hiệu ứng vào phòng
                                                    </Link>
                                                    <Link to="/admin/itemShop-user-decor-manager" className="dropdown-item">
                                                        Quản lí shop trang trí cá nhân
                                                    </Link>
                                                    <Link to="/admin/personal-card" className="dropdown-item">
                                                        Quản lí shop thẻ cá nhân
                                                    </Link>
                                                    <Link to="/admin/sing-effect" className="dropdown-item">
                                                        Quản lí shop hiệu ứng khi hát
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <Link to="/admin/seller-manager" className="dropdown-item w-75 w-75">
                                            <i class="fa-solid fa-money-bill-wave mr-2"></i>
                                            Quản lý seller
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/package-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-cube mr-2"></i>
                                            Quản lí Package
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">2</th>
                                    <td>
                                        <Link to="/admin/item3D" className="dropdown-item w-75">
                                            <i class="fa-brands fa-shopify mr-2"></i>
                                            Quản lí Vật phẩm 3D
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/banner-manager" className="dropdown-item w-75 w-75">
                                            <i class="fa-solid fa-font mr-2"></i>
                                            Quản lí banner
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/set-privilege-admin" className="dropdown-item w-75">
                                            <i class="fa-solid fa-user mr-2"></i>
                                            Quản lí tài khoản Admin
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">3</th>
                                    <td>
                                        <Link to="/admin/categories" className="dropdown-item w-75">
                                            <i class="fa-solid fa-calendar mr-2"></i>
                                            Quản lí chủ đề
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/banner-full" className="dropdown-item w-75">
                                            <i class="fa-solid fa-shop mr-2"></i>
                                            Quản lí banner Full
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/recharge-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-credit-card mr-2"></i>
                                            Quản lí giao dịch
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">4</th>
                                    <td>
                                        <div>
                                            <div class="dropright ml-4">
                                                <i class="fa-solid fa-music mr-2"></i>
                                                <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                    Quản lí cuộc thi
                                                </a>
                                                <div class="dropdown-menu table-secondary">
                                                    <Link to="/admin/contests" className="dropdown-item">
                                                        Danh sách cuộc thi
                                                    </Link>
                                                    <Link to="/admin/theVoiceExamnerPage" className="dropdown-item">
                                                        Thống kê chấm điểm
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </td>

                                    <td>
                                        <Link to="/admin/promotion-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-percent mr-2"></i>
                                            Quản lí khuyến mãi
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/lucky-gift-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-clock-rotate-left mr-2"></i>
                                            Lịch sử nhận bao lì xì
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">5</th>
                                    <td>
                                        <Link to="/admin/vip-privileges" className="dropdown-item w-75">
                                            <i class="fa-solid fa-money-check-dollar mr-2"></i>
                                            Quản lí đặc quyền Vip
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/account-manager/id" className="dropdown-item w-75">
                                            <i class="fa-solid fa-user mr-2"></i>
                                            Quản lí Account
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/cup-history" className="dropdown-item w-75">
                                            <i class="fa-solid fa-trophy mr-2"></i>
                                            Lịch sử tặng cup
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">6</th>
                                    <td>
                                        <Link to="/admin/level-privilege" className="dropdown-item w-75">
                                            <i class="fa-solid fa-angles-up mr-2"></i>
                                            Quản lí đặc quyền level
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/minigame-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-gamepad mr-2"></i>
                                            Quản lí minigame
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/top-vip" className="dropdown-item w-75">
                                            <i class="fa-solid fa-ranking-star mr-2"></i>
                                            Top vip tháng
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">7</th>
                                    <td>
                                        <Link to="/admin/couple" className="dropdown-item w-75">
                                            <i class="fa-solid fa-user-group mr-2"></i>
                                            Quản lí trang trí cặp đôi
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/list-chat" className="dropdown-item w-75">
                                            <i class="fa-solid fa-headset mr-2"></i>
                                            Hỗ trợ người dùng
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/generateLink" className="dropdown-item w-75">
                                            <i class="fa-solid fa-link mr-2"></i>
                                            Lấy deeplink
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">8</th>
                                    <td>
                                        <Link to="/admin/decoration-item-types" className="dropdown-item w-75">
                                            <i class="fa-solid fa-snowman mr-2"></i>
                                            Quản lí trang trí
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/push-notification" className="dropdown-item w-75">
                                            <i class="fa-solid fa-bell mr-2"></i>
                                            Tạo thông báo
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/angular/logcat.html" className="dropdown-item w-75">
                                            <i class="fa-solid fa-users-viewfinder mr-2"></i>
                                            Logcat viewer
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">9</th>
                                    <td>
                                        <Link to="/admin/deep-ar-effect" className="dropdown-item w-75">
                                            <i class="fa-solid fa-trophy mr-2"></i>
                                            Quản lí Hiệu ứng DeepAR
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/gift-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-gift mr-2"></i>
                                            Quản lí Gift
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/privacy-policy" className="dropdown-item w-75">
                                            <i class="fa-solid fa-book mr-2"></i>
                                            Chính sách bảo mật
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">10</th>
                                    <td>
                                        <Link to="/admin/deep-ar-filter" className="dropdown-item w-75">
                                            <i class="fa-solid fa-palette mr-2"></i>
                                            Quản lí Bộ lọc DeepAR
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/content-event" className="dropdown-item w-75">
                                            <i class="fa-solid fa-trophy mr-2"></i>
                                            Quản lí Cup
                                        </Link>                                   </td>
                                    <td>
                                        <Link to="/term" className="dropdown-item w-75">
                                            <i class="fa-solid fa-bell-concierge mr-2"></i>
                                            Dịch vụ
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">11</th>
                                    <td>
                                        <Link to="/admin/medal-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-medal mr-2"></i>
                                            Quản lí Huy Hiệu
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/lucky-money-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-gifts mr-2"></i>
                                            Quản lí bao lì xì
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/birthday-gift" className="dropdown-item w-75">
                                            <i class="fa-solid fa-bell mr-2"></i>
                                            <span>Quản lí Quà sinh nhật</span>
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">12</th>
                                    <td>
                                        <Link to="/admin/survey" className="dropdown-item w-75">
                                            <i class="fa-solid fa-square-poll-horizontal mr-2"></i>
                                            Quản lí khảo sát
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/notify-management" className="dropdown-item w-75">
                                            <i class="fa-solid fa-bell mr-2"></i>
                                            <span>Quản lí thông báo</span>
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/app-policy" className="dropdown-item w-75">
                                            <i class="fa-solid fa-mobile-screen mr-2"></i>
                                            Quản lí Chính sách App
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">13</th>
                                    <td>
                                        <Link to="/admin/game-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-square-poll-horizontal mr-2"></i>
                                            Quản lí game
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/screen-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-mobile-screen mr-2"></i>
                                            Quản lí màn hình
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/icon-event-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-icons mr-2"></i>
                                            Quản lí Icon Event
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">14</th>
                                    <td>
                                        <Link to="/admin/feature-request" className="dropdown-item w-75">
                                            <i class="fa-solid fa-code-pull-request mr-2"></i>
                                            Quản lí Feature Request
                                        </Link>
                                    </td>
                                    <td>
                                        <div>
                                            <div class="dropright ml-4">
                                                <i class="fa-solid fa-microchip mr-2"></i>
                                                <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                    Quản lí Thẻ xử lý
                                                </a>
                                                <div class="dropdown-menu table-secondary">
                                                    <Link to="/admin/recording-ai" className="dropdown-item w-75">
                                                        Xử lý Bài thu
                                                    </Link>
                                                    <Link to="/admin/special-gift" className="dropdown-item w-75">
                                                        Quản lí giảm giá thẻ xử lí AI
                                                    </Link>
                                                    <Link to="/admin/user-special-gift" className="dropdown-item w-75">
                                                        Quản lý Thẻ xử lý User
                                                    </Link>
                                                    <Link to="/admin/recording-vip-users" className="dropdown-item w-75">
                                                        Danh sách User có thẻ xử lý
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <Link to="/admin/user-post" className="dropdown-item w-75">
                                            <i class="fab fa-spotify mr-2"></i>
                                            Quản lí Bài đăng ca sĩ
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">15</th>
                                    <td>
                                        <Link to="/admin/hashtag" className="dropdown-item w-75">
                                            <i class="fa-solid fa-hashtag mr-2"></i>
                                            Quản lí HashTag
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/livestream" className="dropdown-item w-75">
                                            <i class="fa-solid fa-code-pull-request mr-2"></i>
                                            Quản lí Livestream
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/banner-sell-mic" className="dropdown-item w-75">
                                            <i class="fa-solid fa-font mr-2"></i>
                                            Quản lí banner bán hàng
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">16</th>
                                    <td>
                                        <Link to="/admin/recheck-apple" className="dropdown-item w-75">
                                            <i class="fa-solid fa-hashtag mr-2"></i>
                                            Giao dịch Apple
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/real-gift-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-gift mr-2"></i>
                                            Quản lí quà vật lí
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/promo-banner-sell-mic" className="dropdown-item w-75">
                                            <i class="fa-solid fa-font mr-2"></i>
                                            Quản lí Banner Khuyến mãi Bán Mic
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">17</th>
                                    <td>
                                        <Link to="/admin/youtube-midi" className="dropdown-item w-75">
                                            <i class="fa-solid fa-hashtag mr-2"></i>
                                            Danh sách Midi
                                        </Link>
                                    </td>

                                    <td>
                                        <div>
                                            <div class="dropright ml-4">
                                                <i class="fa-solid fa-music mr-2"></i>
                                                <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                    Quản lý game xúc xắc
                                                </a>
                                                <div class="dropdown-menu table-secondary">
                                                    <Link to="/admin/lucky-dice-manager" className="dropdown-item w-75">
                                                        <i class="fa-solid fa-credit-card mr-2"></i>
                                                        Quản lí lịch sử game
                                                    </Link>
                                                    <Link to="/admin/dice-game-info-history" className="dropdown-item w-75">
                                                        <i class="fa-solid fa-door-open mr-2"></i>
                                                        Lịch sử game Xúc xắc
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <Link to="/admin/dead-account-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-face-dizzy mr-2"></i>
                                            Danh sách Account đã ngừng hoạt động
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">18</th>
                                    <td>
                                        <Link to="/admin/new-device-every-day/0" className="dropdown-item w-75">
                                            <i class="fa-solid fa-users mr-2"></i>
                                            Thống kê device mới
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/evenlevel" className="dropdown-item w-75">
                                            <i class="fa-solid fa-hashtag mr-2"></i>
                                            Quản lí quà level
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/botmanager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-robot mr-2"></i>
                                            Quản lí BOT
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">19</th>
                                    <td>
                                        <Link to="/admin/pin-mv-outstand" className="dropdown-item w-75">
                                            <i class="fa-solid fa-photo-film mr-2"></i>
                                            Quản lý MV
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/top-beat-recording" className="dropdown-item w-75">
                                            <i class="fa-solid fa-microphone-lines mr-2"></i>
                                            Top beat song ca
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/bot-interact-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-robot mr-2"></i>
                                            Quản lí tương tác BOT
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">20</th>
                                    <td>
                                        <Link to="/admin/idolmanager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-star mr-2"></i>
                                            Quản lí thần tượng
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/daily-task" className="dropdown-item w-75">
                                            <i class="fa-solid fa-list-check mr-2"></i>
                                            Nhiệm vụ hằng ngày
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/midi-score" className="dropdown-item w-75">
                                            <i class="fa-solid fa-star-half-stroke mr-2"></i>
                                            Chấm điểm Midi
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">21</th>
                                    <td>
                                        <Link to="/admin/client-log" className="dropdown-item w-75">
                                            <i class="fa-solid fa-door-open mr-2"></i>
                                            Quản lí  Client Log
                                        </Link>
                                    </td>
                                    <td>

                                        <Link to="/admin/top_video_recording" className="dropdown-item w-75">
                                            <i class="fa-solid fa-microphone-lines mr-2"></i>
                                            Quản lý Top Mv đề xuất
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/surveyNPS" className="dropdown-item w-75">
                                            <i class="fa-solid fa-square-poll-horizontal mr-2"></i>
                                            Quản lí khảo sát NPS
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">22</th>
                                    <td>
                                        <Link to="/admin/copyright" className="dropdown-item w-75">
                                            <i class="fa-regular fa-copyright mr-2"></i>
                                            Quản lí Bài hát bản quyền
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/virtual-background" className="dropdown-item w-75">
                                            <i class="fa fa-picture-o mr-2"></i>
                                            Quản lý Virtual Background
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/smartlook-manager" className="dropdown-item w-75">
                                            <i class="fa-solid fa-mobile-screen mr-2"></i>
                                            Quản lý SmartLook
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">23</th>
                                    <td>
                                        <Link to="/admin/live-room-info" className="dropdown-item w-75">
                                            <i class="fa fa-picture-o mr-2"></i>
                                            Quản lý phòng Live
                                        </Link>
                                    </td>
                                    <td>
                                        <Link to="/admin/partner" className="dropdown-item w-75">
                                            <i class="fa fa-user mr-2"></i>
                                            Quản lý Cộng Tác Viên
                                        </Link>
                                    </td>

                                    <td>
                                        <div>
                                            <div class="dropright ml-4">
                                                <i class="fa-solid fa-music mr-2"></i>
                                                <a class="dropdown-toggle" data-toggle="dropdown" aria-haspopup="false" aria-expanded="false">
                                                    Quản lý game bắn cá
                                                </a>
                                                <div class="dropdown-menu table-secondary">
                                                    <Link to="/admin/winrate-fish-hunter" className="dropdown-item w-75">
                                                        <i class="fa-solid fa-credit-card mr-2"></i>
                                                        Cài đặt WinRate
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">24</th>
                                    <td>
                                        <Link to= "/admin/manage-vietcombank" className="dropdown-item w-75">
                                            <i class="fa fa-picture-o mr-2"></i>
                                            Quản lý tài khoản VietcomBank
                                        </Link>
                                    </td>
                                </tr>
                                <tr>
                                    
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
}
