import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';

const ModalOnline = ({showModalOnline, uid, action }) => {
    const [showModalOnlineSet, setshowModalOnline] = useState(showModalOnline);
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState(null);
    const [totals, setTotals] = useState({ valid: 0, total: 0 });

    useEffect(() => {
        setshowModalOnline(showModalOnline);
    }, [showModalOnline]);

    useEffect(() => {
        if (showModalOnline && uid) {
            handleSubmitOnline();
        }
    }, [showModalOnline, uid]);

    const handleInputChangeOnline = (event) => {
        const { name, value } = event.target;
        if (name === 'fromDate') setFromDate(value);
        if (name === 'toDate') setToDate(value);
    };

    const handleSubmitOnline = () => {
        let now = new Date();
        // Get first day of current month in local timezone
        let fromDateDefault = new Date(now.getFullYear(), now.getMonth(), 1);
        let timeFrom = fromDate ? new Date(fromDate) : fromDateDefault;
        
        // Set time to 00:00:00 in local timezone
        timeFrom.setHours(0, 0, 0, 0);

        const fromTimestamp = timeFrom.getTime();
        
        // Handle toDate in local timezone - use current time if not selected
        let timeTo = toDate ? new Date(toDate) : now;
        // Set time to 23:59:59 if toDate has value, otherwise keep current time
        if (toDate) {
            timeTo.setHours(23, 59, 59, 999);
        }
        const toTimestamp = timeTo.getTime();

        if (fromTimestamp > toTimestamp) {
            toast.error("Ngày kết thúc phải lớn hơn hoặc bằng ngày bắt đầu!");
            return;
        }

        setIsLoading(true);

        axios.post(
                global.config.apiDomain + '/rest/partner-duration/web/get-partner-timer-list',
                { fromDate: fromTimestamp, toDate: toTimestamp, uid: uid, action: "ONLINE" },
                { headers: { 'Content-Type': 'application/json' } }
            )
            .then((res) => {
                const data = res.data.data || null;
                setIsLoading(false);

                const dates = [];
                const validTimers = [];
                const totalTimers = [];

                if (data && data.list) {
                    Object.entries(data.list).forEach(([key, value]) => {
                        dates.push(convertISODate(key));
                        validTimers.push(value.validTimer);
                        totalTimers.push(value.totalTimer);                      
                    });

                    setChartData({
                        labels: dates,
                        datasets: [
                            {
                                label: 'Thời gian Hợp lệ',
                                data: validTimers,
                                fill: false,
                                borderColor: '#36A2EB',
                                tension: 0.1,
                            },
                            {
                                label: 'Thời gian Tổng',
                                data: totalTimers,
                                fill: false,
                                borderColor: '#4BC0C0',
                                tension: 0.1,
                            },
                        ],
                    });

                    const totalTimer = totalTimers.reduce((acc, curr) => acc + curr, 0);
                    const validTimer = validTimers.reduce((acc, curr) => acc + curr, 0);

                    setTotals({
                        valid: validTimer,
                        total: totalTimer
                    });
                }
            })
            .catch((error) => {
                console.error('Error fetching commissions:', error);
                setIsLoading(false);
            });
    };

    const convertISODate = (isoDate) => {
        const date = new Date(isoDate);
        
        // Lấy ngày, tháng và năm
        const day = date.getDate(); // Lấy ngày (UTC)
        const month = date.getMonth() + 1; // Lấy tháng (UTC, bắt đầu từ 0)
        const year = date.getFullYear(); // Lấy năm (UTC)
        
        // Định dạng thành chuỗi dd/mm/yyyy
        const formattedDate = `${day.toString().padStart(2, "0")}/${month
          .toString()
          .padStart(2, "0")}/${year}`;
        
        return formattedDate;
    }

    return (
        <div>
            <div className="modal fade" id="modalOnline" tabIndex="-1" role="dialog" aria-labelledby="modalCompareLabel" aria-hidden="true">
                <div className="modal-dialog" role="document" style={{ maxWidth: '95%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="resultModalLabel">Biểu đồ thời gian user online </h5>
                            <button type="button" className="close" data-dismiss="modal">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="form-group d-flex" style={{ gap: '10px' }}>
                                <div style={{ flex: 1 }}>
                                    <label>From Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="fromDate"
                                        value={fromDate}
                                        onChange={handleInputChangeOnline}
                                    />
                                </div>
                                <div style={{ flex: 1 }}>
                                    <label>To Date</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        name="toDate"
                                        value={toDate}
                                        onChange={handleInputChangeOnline}
                                    />
                                </div>
                            </div>

                            <button className="btn btn-primary" onClick={handleSubmitOnline}>
                                Hiển thị
                            </button>
                            {isLoading ? (
                                <p>Loading...</p>
                            ) : chartData ? (
                                <div style={{ width: '100%', height: '360px' }}>
                                    <div className='row'>
                                        <div className='col-3'></div>
                                        <div className='col-4'><p><strong>Thời gian Hợp lệ:</strong> {totals.valid}</p></div>
                                        <div className='col-1'></div>
                                        <div className='col-4'><p><strong>Thời gian Tổng:</strong> {totals.total}</p></div>
                                    </div>
                                    <Line data={chartData} options={{ maintainAspectRatio: false }} />
                                </div>
                            ) : (
                                <p>Không có dữ liệu</p>
                            )}
                        </div>
                        <div className="modal-footer">
                            <button className='btn btn-secondary ml-2 mr-2' data-dismiss="modal">Hủy</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalOnline;